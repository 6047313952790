import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './ClassDetails.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';
import { FaTrash } from 'react-icons/fa';

const ClassDetails = () => {
  const { classId } = useParams();
  const navigate = useNavigate();
  const [classData, setClassData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableStudents, setAvailableStudents] = useState([]);
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showQuestions, setShowQuestions] = useState(false);

  // Get user data from localStorage
  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  const schoolName = user?.school;

  useEffect(() => {
    fetchClassDetails();
    fetchAvailableStudents();
    fetchClassQuestions();
  }, [classId]);

  const fetchClassQuestions = async () => {
    try {
      const response = await axios.get(
        `https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/questions/class/${classId}`,
        { headers: getAuthHeaders() }
      );
      if (response.data.status === 'success') {
        setQuestions(response.data.data || []);
      }
    } catch (error) {
      console.error("Error fetching class questions:", error);
    }
  };

  const fetchClassDetails = async () => {
    try {
      const response = await axios.get(
        getApiUrl(API_ENDPOINTS.CLASS.DETAILS(classId)),
        { headers: getAuthHeaders() }
      );
      setClassData(response.data.class);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching class details:", error);
      setError("Failed to load class details");
      setLoading(false);
    }
  };

  const fetchAvailableStudents = async () => {
    try {
      const response = await axios.get(
        getApiUrl(API_ENDPOINTS.TEACHER.SCHOOL_STUDENTS),
        { headers: getAuthHeaders() }
      );
      
      if (response.data.status === 'success') {
        // Filter out students who are already in the class
        const currentStudentIds = classData?.students.map(student => student._id) || [];
        const availableStudents = response.data.data.filter(student => 
          !currentStudentIds.includes(student._id)
        );
        setAvailableStudents(availableStudents);
      }
    } catch (error) {
      console.error("Error fetching available students:", error);
    }
  };

  const handleAddStudents = async () => {
    try {
      await axios.post(
        getApiUrl(API_ENDPOINTS.STUDENT.ASSIGN_TO_CLASS),
        {
          classId: classId,
          studentIds: selectedStudents
        },
        { headers: getAuthHeaders() }
      );

      // Reset state and refresh class details
      setSelectedStudents([]);
      setShowAddStudentsModal(false);
      fetchClassDetails();
    } catch (error) {
      console.error("Error adding students:", error);
      setError("Failed to add students to class");
    }
  };

  const handleStudentSelection = (studentId) => {
    setSelectedStudents(prev => {
      if (prev.includes(studentId)) {
        return prev.filter(id => id !== studentId);
      } else {
        return [...prev, studentId];
      }
    });
  };

  const handleRemoveStudent = async (studentId) => {
    try {
      await axios.post(
        getApiUrl(API_ENDPOINTS.STUDENT.REMOVE_FROM_CLASS),
        {
          classId: classId,
          studentId: studentId
        },
        { headers: getAuthHeaders() }
      );
      fetchClassDetails();
    } catch (error) {
      console.error("Error removing student:", error);
      setError("Failed to remove student from class");
    }
  };

  const handleViewSubmissions = (studentId) => {
    navigate(`/class/${classId}/student/${studentId}/submissions`);
  };

  const handleRemoveQuestion = async (questionId) => {
    try {
      const response = await axios.post(
        `https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/questions/remove-from-class`,
        {
          questionId,
          classId
        },
        { headers: getAuthHeaders() }
      );
      
      if (response.data.status === 'success') {
        // Update questions list
        setQuestions(questions.filter(q => q._id !== questionId));
      }
    } catch (error) {
      console.error("Error removing question from class:", error);
      setError("Failed to remove question from class");
    }
  };

  if (loading) {
    return <div className="class-details-container">
      <div className="loading">Loading class details...</div>
    </div>;
  }

  if (error) {
    return <div className="class-details-container">
      <div className="error">{error}</div>
    </div>;
  }

  if (!classData) {
    return <div className="class-details-container">
      <div className="error">Class not found</div>
    </div>;
  }

  return (
    <div className="class-details-container">
      <div className="content-section">
        <div className="section-header">
          <h1>{classData.name}</h1>
          <button 
            className="primary-button"
            onClick={() => setShowQuestions(!showQuestions)}
          >
            {showQuestions ? 'Hide Questions' : 'View Questions'}
          </button>
        </div>

        {showQuestions && (
          <div className="content-section">
            <div className="section-header">
              <h2>Questions ({questions.length})</h2>
            </div>
            
            {questions.length === 0 ? (
              <div className="empty-state">
                <p>No questions assigned to this class yet.</p>
              </div>
            ) : (
              <div className="table-container">
                <table className="questions-table">
                  <thead>
                    <tr>
                      <th>Question Text</th>
                      <th>Total Marks</th>
                      <th>Mark Range</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questions.map((question) => (
                      <tr key={question._id}>
                        <td>{question.question}</td>
                        <td className="text-center">{question.marks}</td>
                        <td className="text-center">{question.lowMarkRange} - {question.highMarkRange}</td>
                        <td>
                          <button
                            className="icon-button"
                            onClick={() => handleRemoveQuestion(question._id)}
                            title="Remove from Class"
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

        <div className="content-section">
          <div className="section-header">
            <h2>Students ({classData.students.length})</h2>
          </div>
          
          {classData.students.length === 0 ? (
            <div className="empty-state">
              <p>No students in this class yet.</p>
            </div>
          ) : (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Student Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {classData.students.map((student) => (
                    <tr key={student._id}>
                      <td>{student.name}</td>
                      <td>{student.email}</td>
                      <td>
                        <button
                          className="icon-button"
                          onClick={() => handleRemoveStudent(student._id)}
                          title="Remove Student"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassDetails; 
import { useEffect, useState } from "react";
import "./teacher.styles.scss";
import axios from "axios";
import RecordBar from "./../../components/ui/Record-bar/record-bar.component";
import ButtonComponent from "./../../components/ui/buttonComponent/button.component";
import { Link } from "react-router-dom";
// import Logo from "../../components/Logo/logo.component";
import Pagination from "./../../components/ui/Pagination/pagination.component";
const TeacherPage = () => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [classes, setClasses] = useState([]);
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage
  // const [classesPerPage, setClassesPerPage] = useState(7); // Define number of classes per page


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend-master-v8ob.onrender.com/api/v1/stem@home/teacher/classes",
          //"http://localhost:8080/api/v1/stem@home/teacher/classes",
          {
            headers: { 
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}` // Include the token in the Authorization header
            }
          }
        );
        setClasses(response.data.classes);
        console.log("Fetched results:", response.data.classes); // Log the results here
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function to fetch data on page load
  }, [token]); // Add token as a dependency to useEffect

  // Calculate paginated classes
  // const indexOfLastClass = page * classesPerPage;
  // const indexOfFirstClass = indexOfLastClass - classesPerPage;
  // const currentClasses = classes.slice(indexOfFirstClass, indexOfLastClass);

  return (
    <div className="teacher-page">
      {error ? (
        <div>
          <h1>{error}</h1>
        </div>
      ) : classes.length === 0 ? (
        <div>
          <h1>No classes to show at the moment</h1>
        </div>
      ) : (
        <>
          <h1 className="header">Teacher Classes</h1>
          <div className="classes-list">
            {classes.map((classItem) => (
              <div key={classItem._id} className="class-item">
                <h2>{classItem.name}</h2>
                <p>Students Count: {classItem.students.length}</p>
              </div>
            ))}
          </div>
          <Link to={"/"}>
            <ButtonComponent>Back to home</ButtonComponent>
          </Link>
        </>
      )}
    </div>
  );
  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://localhost:8080/api/v1/stem@home/teacher/getResult?page=${page}`
  //     )
  //     .then((response) => {
  //       if (response.data) {
  //         setResult(response.data);
  //       } else {
  //         setError(response.data.error);
  //       }
  //     })
  //     .catch((err) => {
  //       setError(err.message || "An error occurred");
  //     });
  // }, [page]);

  // return (
  //   <div className="teacher-page">
  //     {error ? (
  //       <div>
  //         <h1>{error}</h1>
  //       </div>
  //     ) : result === null ? (
  //       <div>
  //         <h1>There is no result to show as of now</h1>
  //       </div>
  //     ) : (
  //       <>
  //         {/* <Logo /> */}
  //         <h1 className="header">Teacher</h1>
  //         {result.data.map((user) => {
  //           return <RecordBar key={user._id} user={user} />;
  //         })}
  //         <Pagination
  //           totalPosts={result.totalLength}
  //           postsPerPage={7}
  //           setCurrentPage={setPage}
  //           currentPage={page}
  //         />
  //         <Link to={"/"}>
  //           {" "}
  //           <ButtonComponent>Back to home</ButtonComponent>{" "}
  //         </Link>
  //       </>
  //     )}
  //   </div>
  // );
};

export default TeacherPage;
// API Configuration
const getBaseUrl = () => {
  // For local development and testing
  // Use your computer's IP address when testing on mobile devices
  return 'https://backend-master-v8ob.onrender.com';  // Your computer's IP address
};

export const API_BASE_URL = getBaseUrl();

// API Endpoints grouped by feature
export const API_ENDPOINTS = {
  // Auth endpoints
  LOGIN: '/api/v1/stem@home/auth/login',
  REFRESH_TOKEN: '/api/v1/stem@home/auth/refresh-token',
  
  // Student endpoints
  STUDENT: {
    ALL: '/api/v1/stem@home/student/allStudents',
    CLASSES: (studentId) => `/api/v1/stem@home/student/${studentId}/enrolled-classes`,
    SUBMISSIONS: '/api/v1/stem@home/student/submissions',
    ASSIGN_TO_CLASS: '/api/v1/stem@home/student/assign-students',
    REMOVE_FROM_CLASS: '/api/v1/stem@home/student/remove-from-class',
    CLASS_SUBMISSIONS: (classId, studentId) => `/api/v1/stem@home/student/class/${classId}/student/${studentId}/submissions`,
    SUBMISSION_DETAILS: (submissionId) => `/api/v1/stem@home/student/submissions/${submissionId}`,
    RESULTS: (resultId) => `/api/v1/stem@home/student/results/${resultId}`,
    FEEDBACK: (feedbackId) => `/api/v1/stem@home/student/getFeedback/${feedbackId}`,
    GET_RESULT: (page) => `/api/v1/stem@home/student/getResult?page=${page}`
  },

  // Teacher endpoints
  TEACHER: {
    ALL: '/api/v1/stem@home/teacher/allTeachers',
    CLASSES: '/api/v1/stem@home/teacher/classes',
    SUBMISSIONS: '/api/v1/stem@home/teacher/submissions',
    SCHOOL_STUDENTS: '/api/v1/stem@home/teacher/school-students',
    QUESTIONS: (teacherId) => `/api/v1/stem@home/teacher/questions/${teacherId}`,
    GET_RESULT: (page) => `/api/v1/stem@home/teacher/getResult?page=${page}`
  },

  // Class endpoints
  CLASS: {
    ALL: '/api/v1/stem@home/class/allClasses',
    CREATE: '/api/v1/stem@home/class/create',
    DETAILS: (classId) => `/api/v1/stem@home/class/${classId}`,
    QUESTIONS: (classId) => `/api/v1/stem@home/class/${classId}/questions`
  },

  // Question endpoints
  QUESTION: {
    DETAILS: (id) => `/api/v1/stem@home/questions/${id}`,
    ADD_TO_CLASS: '/api/v1/stem@home/question/questions/add-to-class',
    DELETE: (id) => `/api/v1/stem@home/questions/delete/${id}`,
    UPDATE: (id) => `/api/v1/stem@home/questions/${id}`,
    CREATE: '/api/v1/stem@home/question/questions/create'
  },

  // Admin endpoints
  ADMIN: {
    USERS: '/api/v1/stem@home/admin/users',
    USER_DETAILS: (userId) => `/api/v1/stem@home/admin/users/${userId}`,
    USER_SUBMISSIONS: (userId) => `/api/v1/stem@home/admin/users/${userId}/submissions`
  },

  // Submission endpoints
  SUBMISSION: {
    GET: (submissionId) => `/api/v1/stem@home/student/submissions/${submissionId}`,
    LIST: '/api/v1/stem@home/student/submissions',
    CLASS: (classId, studentId) => `/api/v1/stem@home/student/class/${classId}/student/${studentId}/submissions`,
    CREATE: '/api/v1/stem@home/student/submissions/create',
    GRADE: (submissionId) => `/api/v1/stem@home/student/submissions/${submissionId}/grade`
  },

  UPLOAD: '/api/v1/stem@home/upload'
};

// Helper function to get full API URL
export const getApiUrl = (endpoint) => `${API_BASE_URL}${endpoint}`;

// Helper function to get headers with auth token
export const getAuthHeaders = () => ({
  'Authorization': `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json'
}); 
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import './StartSubmission.css';
import axios from 'axios';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';
import imageCompression from 'browser-image-compression';

const QuestionView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [answers, setAnswers] = useState({});
  const [scores, setScores] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  const [isAnswerRequired, setIsAnswerRequired] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [extractedText, setExtractedText] = useState('');
  const [processingImage, setProcessingImage] = useState(false);
  const [showAnswerSection, setShowAnswerSection] = useState(false);
  const [selectedInputMethod, setSelectedInputMethod] = useState(null);
  const [submissionSaved, setSubmissionSaved] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));

  // Get class name and teacher name from location state or localStorage
  const className = location.state?.className || 
    JSON.parse(localStorage.getItem('classes') || '[]')
      .find(c => c._id === classId)?.name || 
    'Class';
  
  const teacherName = location.state?.teacherName || 
    JSON.parse(localStorage.getItem('classes') || '[]')
      .find(c => c._id === classId)?.teacher || 
    'Not assigned';

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role === 'teacher') {
      navigate('/dashboard');
      return;
    }

    fetchQuestions();
  }, [classId, navigate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await axios.get(
        getApiUrl(API_ENDPOINTS.CLASS.QUESTIONS(classId)),
        { headers: getAuthHeaders() }
      );
      
      if (response.data && response.data.success) {
        setQuestions(response.data.questions || []);
      } else {
        setError("No questions found for this class");
        setQuestions([]);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
      setError("Failed to load questions. Please try again.");
      setQuestions([]);
    } finally {
      setLoading(false);
    }
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 9.5,          // Slightly under the 10MB limit to be safe
      maxWidthOrHeight: 2048,   // Increased max dimension while maintaining aspect ratio
      useWebWorker: true,
      initialQuality: 0.8       // Start with good quality
    };
    
    try {
      const compressedFile = await imageCompression(imageFile, options);
      
      // If still too large, compress further
      if (compressedFile.size > 9.5 * 1024 * 1024) {
        options.maxSizeMB = 5;
        options.initialQuality = 0.7;
        return await imageCompression(compressedFile, options);
      }
      
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error;
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Remove the data:image/jpeg;base64, prefix
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setProcessingImage(true);
      
      try {
        // Compress the image first
        const compressedImage = await compressImage(file);
        
        // Convert compressed image to base64
        const base64String = await convertToBase64(compressedImage);

        const response = await axios.post(
          getApiUrl(API_ENDPOINTS.UPLOAD),
          { image: base64String },
          {
            headers: {
              'Content-Type': 'application/json',
              ...getAuthHeaders()
            }
          }
        );

        if (response.data.status === "successful") {
          const extractedText = response.data.data.formData;
          setCurrentAnswer(extractedText);
          setShowAnswerSection(true);
        } else {
          throw new Error('Failed to process image');
        }
      } catch (error) {
        console.error('Error processing image:', error);
        setError('Failed to process image. Please try again.');
      } finally {
        setProcessingImage(false);
      }
    }
  };

  const handleCameraCapture = () => {
    navigate('/takeImage', {
      state: {
        returnTo: `/start-submission/${classId}`,
        currentQuestionIndex,
        className,
        onImageCapture: async (imageFile) => {
          setSelectedImage(imageFile);
          setProcessingImage(true);
          
          try {
            // Compress the image first
            const compressedImage = await compressImage(imageFile);
            
            // Convert compressed image to base64
            const base64String = await convertToBase64(compressedImage);

            const response = await axios.post(
              getApiUrl(API_ENDPOINTS.UPLOAD),
              { image: base64String },
              {
                headers: {
                  'Content-Type': 'application/json',
                  ...getAuthHeaders()
                }
              }
            );

            if (response.data.status === "successful") {
              const extractedText = response.data.data.formData;
              setCurrentAnswer(extractedText);
              setShowAnswerSection(true);
            } else {
              throw new Error('Failed to process image');
            }
          } catch (error) {
            console.error('Error processing image:', error);
            setError('Failed to process image. Please try again.');
          } finally {
            setProcessingImage(false);
          }
        }
      }
    });
  };

  const handleBack = () => {
    navigate(`/student/class/${classId}`);
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex(prev => Math.max(0, prev - 1));
    setCurrentAnswer(answers[questions[currentQuestionIndex - 1]?._id] || '');
  };

  const handleNext = async () => {
    if (!currentAnswer.trim()) {
      setIsAnswerRequired(true);
      return;
    }

    const currentQuestion = questions[currentQuestionIndex];
    const newAnswers = { ...answers, [currentQuestion.id]: currentAnswer };
    setAnswers(newAnswers);

    if (currentQuestionIndex === questions.length - 1) {
      setSubmissionSaved(true); // Show saving feedback
      try {
        const submissions = questions.map(question => ({
          questionId: question.id,
          answer: newAnswers[question.id] || ''
        }));

        const submissionData = {
          classId: classId,
          submissions: submissions,
          totalScore: 0,
          status: 'pending'
        };

        const response = await axios.post(
          getApiUrl(API_ENDPOINTS.SUBMISSION.CREATE),
          submissionData,
          { headers: getAuthHeaders() }
        );

        // Navigate to the specific submission after 2 seconds
        setTimeout(() => {
          navigate(`/my-submissions/${response.data.data._id}`);
        }, 2000);

      } catch (error) {
        console.error('Error saving submission:', error);
        setError(error.response?.data?.message || error.message || 'Failed to save submission. Please try again.');
        setSubmissionSaved(false);
      }
    } else {
      setCurrentQuestionIndex(prev => prev + 1);
      setCurrentAnswer('');
    }
  };

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  const currentQuestion = questions[currentQuestionIndex];
  const totalMarks = questions.reduce((sum, q) => sum + (q.marks || 0), 0);

  return (
    <div className="submission-container">
      {submissionSaved && (
        <div className="submission-overlay">
          <div className="submission-feedback">
            Saving your submission...
          </div>
        </div>
      )}
      
      <div className="class-name-header">
        <h2>{className}</h2>
      </div>

      <div className="question-container">
        <div className="total-info">
          <div>Total Questions: {questions.length}</div>
        </div>

        <div className="question-item">
          <div className="question-header">
            <span>Question {currentQuestionIndex + 1}</span>
            <span className="marks">{currentQuestion?.marks} marks</span>
          </div>
          <div className="question-content">
            <p>{currentQuestion?.question}</p>
          </div>
        </div>

        <div className="answer-label">Answer:</div>
        <div className="input-options">
          <div 
            className="input-option"
            onClick={() => {
              setSelectedInputMethod('camera');
              document.getElementById('image-upload').click();
            }}
          >
            <i>📸</i>
            <span>Take Photo</span>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              capture="environment"
            />
          </div>

          <div 
            className="input-option"
            onClick={() => {
              setSelectedInputMethod('upload');
              document.getElementById('image-upload-gallery').click();
            }}
          >
            <i>📁</i>
            <span>Upload Image</span>
            <input
              id="image-upload-gallery"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
          </div>

          <div 
            className="input-option"
            onClick={() => {
              setSelectedInputMethod('text');
              setShowAnswerSection(true);
            }}
          >
            <i>✏️</i>
            <span>Type Answer</span>
          </div>
        </div>

        <div className={`answer-section ${showAnswerSection ? 'visible' : ''}`}>
          <textarea
            value={currentAnswer}
            onChange={(e) => {
              setCurrentAnswer(e.target.value);
              setIsAnswerRequired(false);
            }}
            placeholder="Type your answer here..."
          />
          {isAnswerRequired && (
            <div className="error-message">Please provide an answer</div>
          )}
        </div>

        {processingImage && (
          <div className="processing-message">
            Processing image...
          </div>
        )}

        <div className="navigation-buttons">
          <button
            onClick={handlePrevious}
            disabled={currentQuestionIndex === 0}
            className="nav-button"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="nav-button primary"
          >
            {currentQuestionIndex === questions.length - 1 ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionView; 
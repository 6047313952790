import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Submissions.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const Submissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [sortBy, setSortBy] = useState('recent');
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const navigate = useNavigate();
  
  // Get user role from the user object in localStorage
  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  const userRole = user?.role?.toLowerCase();

  const fetchSubmissions = async () => {
    try {
      let endpoint;
      if (userRole === 'teacher') {
        endpoint = `/api/v1/stem@home/question/submissions/teacher/${user.userId}`;
      } else {
        endpoint = API_ENDPOINTS.STUDENT.SUBMISSIONS;
      }

      const response = await axios.get(
        getApiUrl(endpoint),
        { headers: getAuthHeaders() }
      );
      console.log('Submissions response:', response.data);
      setSubmissions(response.data.data || []);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching submissions:', err);
      setError('Failed to load submissions');
      setLoading(false);
    }
  };

  // Separate effect for fetching submissions
  useEffect(() => {
    fetchSubmissions();
  }, []); // Only run once on mount

  // Separate effect for handling view submission details event
  useEffect(() => {
    const handleViewSubmissionDetails = (event) => {
      const submissionId = event.detail.submissionId;
      const submission = submissions.find(s => s._id === submissionId);
      if (submission) {
        handleViewSubmission(submission);
      }
    };

    window.addEventListener('viewSubmissionDetails', handleViewSubmissionDetails);

    return () => {
      window.removeEventListener('viewSubmissionDetails', handleViewSubmissionDetails);
    };
  }, [submissions]); // Only re-run when submissions change

  const handleViewSubmission = async (submission) => {
    setSelectedSubmission(submission);
    setShowDetailsModal(true);
  };

  const closeModal = () => {
    setShowDetailsModal(false);
    setSelectedSubmission(null);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleSort = (value) => {
    setSortBy(value);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const getSortedAndFilteredSubmissions = () => {
    let filteredSubmissions = [...submissions];

    // Apply search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filteredSubmissions = filteredSubmissions.filter(submission => 
        submission.student?.name?.toLowerCase().includes(query) ||
        submission.class?.name?.toLowerCase().includes(query)
      );
    }

    // Apply sorting
    switch (sortBy) {
      case 'recent':
        return filteredSubmissions.sort((a, b) => 
          new Date(b.submittedAt) - new Date(a.submittedAt)
        );
      case 'oldest':
        return filteredSubmissions.sort((a, b) => 
          new Date(a.submittedAt) - new Date(b.submittedAt)
        );
      case 'name':
        return filteredSubmissions.sort((a, b) => 
          (a.student?.name || '').localeCompare(b.student?.name || '')
        );
      case 'class':
        return filteredSubmissions.sort((a, b) => 
          (a.class?.name || '').localeCompare(b.class?.name || '')
        );
      case 'score':
        return filteredSubmissions.sort((a, b) => 
          (b.totalScore || 0) - (a.totalScore || 0)
        );
      default:
        return filteredSubmissions;
    }
  };

  const toggleQuestion = (index) => {
    setExpandedQuestions(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  if (loading) {
    return <div className="submissions-container loading">Loading submissions...</div>;
  }

  if (error) {
    return <div className="submissions-container error">{error}</div>;
  }

  const sortedAndFilteredSubmissions = getSortedAndFilteredSubmissions();

  return (
    <div className="submissions-container">
      <div className="header">
        <h1>All Submissions ({submissions.length})</h1>
        {submissions.length > 0 && (
          <div className="filters">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search by student or class name..."
                value={searchQuery}
                onChange={handleSearch}
                className="search-input"
              />
            </div>
            <select 
              value={sortBy} 
              onChange={(e) => handleSort(e.target.value)}
              className="sort-select"
            >
              <option value="recent">Most Recent</option>
              <option value="oldest">Oldest First</option>
              <option value="name">Student Name</option>
              <option value="class">Class Name</option>
              <option value="score">Score</option>
            </select>
          </div>
        )}
      </div>

      {submissions.length === 0 ? (
        <div className="empty-state">
          {userRole === 'teacher' ? (
            <>
              <p>There are no submissions yet.</p>
              <button className="view-button" onClick={() => navigate('/classes')}>
                View Available Classes
              </button>
            </>
          ) : (
            <>
              <p>You haven't made any submissions yet.</p>
              <button className="view-button" onClick={() => navigate('/classes')}>
                View Available Classes
              </button>
            </>
          )}
        </div>
      ) : (
        <div className="submissions-list">
          {sortedAndFilteredSubmissions.map((submission) => (
            <div 
              key={submission._id} 
              className="submission-card"
            >
              <div className="submission-content">
                <div className="submission-info">
                  <div className="submission-header">
                    <h3>Class: {submission.class?.name || "2"}</h3>
                    <div className="student-name">Student: {submission.student?.name || "Snap Student"}</div>
                    <div className="submission-date">{formatDate(submission.submittedAt)}</div>
                  </div>
                  
                  <div className="submission-score">
                    <h4>Total Score: {submission.totalScore}</h4>
                    <div className="questions-count">
                      Questions: {submission.submissions?.length || 1}
                    </div>
                  </div>
                </div>
                
                <button 
                  className="view-button"
                  onClick={() => handleViewSubmission(submission)}
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {showDetailsModal && selectedSubmission && (
        <div className="popup-overlay" onClick={closeModal}>
          <div className="popup" onClick={e => e.stopPropagation()}>
            <div className="popup-header">
              <h2>Submission Details</h2>
              <button onClick={closeModal} className="close-button">&times;</button>
            </div>
            <div className="popup-content">
              <div className="details-section">
                <div className="submission-info-row">
                  <div className="detail-row compact">
                    <span className="detail-label">Class:</span>
                    <span className="detail-value">{selectedSubmission.class?.name || "2"}</span>
                  </div>
                  <div className="detail-row compact">
                    <span className="detail-label">Student:</span>
                    <span className="detail-value">{selectedSubmission.student?.name || "Snap Student"}</span>
                  </div>
                  <div className="detail-row compact">
                    <span className="detail-label">Submitted:</span>
                    <span className="detail-value">{formatDate(selectedSubmission.submittedAt)}</span>
                  </div>
                </div>

                <div className="questions-section">
                  {selectedSubmission.submissions.map((submission, index) => (
                    <div key={index} className="question-container">
                      <button 
                        className={`question-header ${expandedQuestions.includes(index) ? 'expanded' : ''}`}
                        onClick={() => toggleQuestion(index)}
                      >
                        <span className="question-title">Question {index + 1}</span>
                        <div className="question-summary">
                          <span className="score-badge">
                            Score: {submission.score}/{submission.marks}
                          </span>
                          <span className="expand-icon">
                            {expandedQuestions.includes(index) ? '−' : '+'}
                          </span>
                        </div>
                      </button>
                      
                      {expandedQuestions.includes(index) && (
                        <div className="question-details">
                          <div className="detail-row">
                            <span className="detail-label">Question:</span>
                            <span className="detail-value">{submission.question}</span>
                          </div>
                          <div className="detail-row">
                            <span className="detail-label">Total Marks:</span>
                            <span className="detail-value">{submission.marks}</span>
                          </div>
                          <div className="detail-row">
                            <span className="detail-label">Score:</span>
                            <span className="detail-value">{submission.score}</span>
                          </div>
                          <div className="detail-row">
                            <span className="detail-label">Answer:</span>
                            <span className="detail-value answer">{submission.answer}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Submissions;

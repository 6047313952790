import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Toast, Badge } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomPopup from '../../components/ui/CustomPopup/CustomPopup';
import './Users.css';

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // State management
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterRole, setFilterRole] = useState(location.state?.filterRole || 'all');
  
  // Modal states
  const [deleteModal, setDeleteModal] = useState({ show: false, user: null });
  const [promoteModal, setPromoteModal] = useState({ show: false, user: null });
  const [toast, setToast] = useState({ show: false, message: '' });

  // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch('https://backend-master-v8ob.onrender.com/api/v1/stem@home/admin/users', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch users (Status: ${response.status})`);
        }

        const data = await response.json();
        setUsers(Array.isArray(data) ? data : (data.users || []));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Handle user deletion
  const handleDelete = async (user) => {
    try {
      const response = await fetch('https://backend-master-v8ob.onrender.com/api/v1/stem@home/admin/delete-users', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "users": [user.id]
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete user');
      }

      setUsers(users.filter(u => u.id !== user.id));
      setToast({ show: true, message: 'User deleted successfully' });
      setDeleteModal({ show: false, user: null });
    } catch (err) {
      setError(err.message);
      setToast({ show: true, message: 'Failed to delete user: ' + err.message });
    }
  };

  // Handle user promotion
  const handlePromote = async (user) => {
    try {
      const response = await fetch('https://backend-master-v8ob.onrender.com/api/v1/stem@home/admin/users/promote', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userIds: [user._id] })
      });

      if (!response.ok) {
        throw new Error('Failed to promote user');
      }

      setUsers(users.map(u => u._id === user._id ? { ...u, role: 'teacher' } : u));
      setToast({ show: true, message: 'User promoted successfully' });
      setPromoteModal({ show: false, user: null });
    } catch (err) {
      setError(err.message);
    }
  };

  // Filter and sort users
  const filteredAndSortedUsers = users
    .filter(user => {
      const matchesSearch = (user.name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                          (user.email?.toLowerCase() || '').includes(searchTerm.toLowerCase());
      const matchesRole = filterRole === 'all' || user.role === filterRole;
      return matchesSearch && matchesRole;
    })
    .sort((a, b) => {
      const aValue = (a[sortField] || '').toLowerCase();
      const bValue = (b[sortField] || '').toLowerCase();
      return sortDirection === 'asc' 
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

  if (loading) {
    return <div className="text-center mt-4">Loading...</div>;
  }

  if (error) {
    return <div className="alert alert-danger mt-4">{error}</div>;
  }

  return (
    <Container fluid className="mt-4">
      {/* Page Header */}
      <div className="page-header mb-4">
        <h1>All Users for {JSON.parse(localStorage.getItem('user'))?.school || 'School'}</h1>
      </div>

      {/* Search and Filter Controls */}
      <div className="mb-4 d-flex gap-3">
        <Form.Control
          type="text"
          placeholder="Search by name or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-auto flex-grow-1"
        />
        <select 
          className="form-select w-auto"
          value={filterRole}
          onChange={(e) => setFilterRole(e.target.value)}
        >
          <option value="all">All Users</option>
          <option value="student">Students Only</option>
          <option value="teacher">Teachers Only</option>
        </select>
      </div>

      {/* Users Table */}
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th onClick={() => {
                if (sortField === 'name') {
                  setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                } else {
                  setSortField('name');
                  setSortDirection('asc');
                }
              }} style={{ cursor: 'pointer' }}>
                Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => {
                if (sortField === 'email') {
                  setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                } else {
                  setSortField('email');
                  setSortDirection('asc');
                }
              }} style={{ cursor: 'pointer' }}>
                Email {sortField === 'email' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => {
                if (sortField === 'role') {
                  setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                } else {
                  setSortField('role');
                  setSortDirection('asc');
                }
              }} style={{ cursor: 'pointer' }}>
                Role {sortField === 'role' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedUsers.map(user => (
              <tr key={user._id} className={user.role === 'student' ? 'table-success' : 'table-info'}>
                <td>
                  <span 
                    className="user-name-link"
                    onClick={() => {
                      console.log('User:', user); // For debugging
                      navigate(`/users/${user._id}`);
                    }}
                  >
                    {user.name}
                  </span>
                </td>
                <td>
                  <a 
                    href={`mailto:${user.email}`}
                    className="email-link"
                  >
                    {user.email}
                  </a>
                </td>
                <td>
                  <Badge 
                    bg={user.role === 'student' ? 'success' : undefined}
                    style={user.role === 'teacher' ? { backgroundColor: '#60a5fa', color: 'white' } : undefined}
                  >
                    {user.role}
                  </Badge>
                </td>
                <td>
                  <div className="d-flex gap-2">
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => setDeleteModal({ show: true, user })}
                    >
                      Delete
                    </Button>
                    {user.role === 'student' && (
                      <Button
                        size="sm"
                        style={{ backgroundColor: '#321E3C', borderColor: '#321E3C' }}
                        onClick={() => setPromoteModal({ show: true, user })}
                      >
                        Promote
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Delete Modal */}
      <CustomPopup
        show={deleteModal.show}
        onHide={() => setDeleteModal({ show: false, user: null })}
        title="Confirm Delete User"
      >
        {deleteModal.user && (
          <>
            <p>Are you sure you want to delete this user?</p>
            <div className="info-group">
              <strong>Name:</strong> {deleteModal.user.name}
            </div>
            <div className="info-group">
              <strong>Email:</strong> {deleteModal.user.email}
            </div>
            <div className="info-group">
              <strong>ID:</strong> {deleteModal.user.id}
            </div>
            <div className="button-group">
              <button 
                className="cancel-btn"
                onClick={() => setDeleteModal({ show: false, user: null })}
              >
                Cancel
              </button>
              <button 
                className="confirm-btn delete"
                onClick={() => handleDelete(deleteModal.user)}
              >
                Delete User
              </button>
            </div>
          </>
        )}
      </CustomPopup>

      {/* Promote Modal */}
      <CustomPopup
        show={promoteModal.show}
        onHide={() => setPromoteModal({ show: false, user: null })}
        title="Promote to Teacher"
      >
        {promoteModal.user && (
          <>
            <p>Are you sure you want to promote this user to teacher?</p>
            <div className="info-group">
              <strong>Name:</strong> {promoteModal.user.name}
            </div>
            <div className="info-group">
              <strong>Email:</strong> {promoteModal.user.email}
            </div>
            <div className="button-group">
              <button 
                className="cancel-btn"
                onClick={() => setPromoteModal({ show: false, user: null })}
              >
                Cancel
              </button>
              <button 
                className="confirm-btn promote"
                onClick={() => handlePromote(promoteModal.user)}
              >
                Promote User
              </button>
            </div>
          </>
        )}
      </CustomPopup>

      {/* Success Toast */}
      <Toast
        show={toast.show}
        onClose={() => setToast({ show: false, message: '' })}
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 9999,
          minWidth: '250px'
        }}
        bg="success"
        delay={3000}
        autohide
      >
        <Toast.Header closeButton={false}>
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{toast.message}</Toast.Body>
      </Toast>

      {filteredAndSortedUsers.length === 0 && (
        <div className="text-center mt-4">
          <p className="text-muted">No users found matching your criteria.</p>
        </div>
      )}
    </Container>
  );
};

export default Users; 
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './ClassDetails.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const ClassDetails = () => {
  const [questions, setQuestions] = useState([]);
  const [classDetails, setClassDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id: classId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { className, teacherName } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data for class:', classId);
        
        // First fetch class details if not provided in state
        if (!className || !teacherName) {
          const classResponse = await axios.get(
            `https://backend-master-v8ob.onrender.com/api/v1/stem@home/class/${classId}`,
            { headers: getAuthHeaders() }
          );
          setClassDetails(classResponse.data.class);
        }

        // Then fetch questions
        const questionsResponse = await axios.get(
          `https://backend-master-v8ob.onrender.com/api/v1/stem@home/class/${classId}/questions`,
          { headers: getAuthHeaders() }
        );

        console.log('Questions response:', questionsResponse.data);

        if (questionsResponse.data.success) {
          setQuestions(questionsResponse.data.questions || []);
        } else {
          setQuestions([]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.response?.data?.message || "Failed to load class data");
        setLoading(false);
      }
    };

    if (classId) {
      fetchData();
    }
  }, [classId, className, teacherName]);

  const handleStartSubmission = () => {
    navigate(`/start-submission/${classId}`, {
      state: { 
        className: className || classDetails?.name
      }
    });
  };

  if (loading) {
    return <div className="class-details-container">
      <div className="loading">Loading class details...</div>
    </div>;
  }

  if (error) {
    return <div className="class-details-container">
      <div className="error">{error}</div>
    </div>;
  }

  // Use either state values or fetched class details
  const displayClassName = className || classDetails?.name;
  const displayTeacherName = teacherName || classDetails?.teacher?.name || 'Not assigned';

  return (
    <div className="class-details-container">
      <div className="questions-overview">
        <h1 className="class-title">{displayClassName}</h1>
        <div className="class-info">
          <div className="info-line">Teacher: {displayTeacherName}</div>
          <div className="info-line">Total Questions: {questions.length}</div>
        </div>
        
        <div className="marks-breakdown">
          {questions.map((question, index) => (
            <div key={question._id || index} className="question-line">
              <span>Question {index + 1}:</span>
              <span>{question.marks} marks</span>
            </div>
          ))}
        </div>

        <div className="action-section">
          <button 
            className="start-submission-button"
            onClick={handleStartSubmission}
            disabled={questions.length === 0}
          >
            Start Submission
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClassDetails; 
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const Dashboard = () => {
  const [stats, setStats] = useState({});
  const [recentSubmissions, setRecentSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (!userData) {
          throw new Error('No user data found');
        }
        const user = JSON.parse(userData);
        setUserRole(user.role);

        if (user.role.toLowerCase() === 'teacher') {
          // Fetch teacher stats
          try {
            const classesResponse = await axios.get(
              getApiUrl(API_ENDPOINTS.TEACHER.CLASSES),
              { headers: getAuthHeaders() }
            );

            const questionsResponse = await axios.get(
              `https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/questions/teacher/${user.userId}`,
              { headers: getAuthHeaders() }
            );

            const submissionsResponse = await axios.get(
              `https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/submissions/teacher/${user.userId}`,
              { headers: getAuthHeaders() }
            );

            setStats({
              activeClasses: classesResponse.data.data?.length || 0,
              totalQuestions: questionsResponse.data.data?.length || 0,
              totalSubmissions: submissionsResponse.data.data?.length || 0
            });

            // Set recent submissions for teacher
            if (submissionsResponse.data.data) {
              const recentOnes = submissionsResponse.data.data
                .slice(0, 5)
                .map(submission => ({
                  _id: submission._id,
                  submittedAt: submission.submittedAt,
                  student: { name: submission.student?.name || 'Unknown Student' },
                  class: { name: submission.class?.name || 'Unknown Class' },
                  totalScore: submission.totalScore
                }));
              setRecentSubmissions(recentOnes);
            }
          } catch (err) {
            console.error('Error fetching teacher stats:', err);
            // Set default values instead of showing error
            setStats({
              activeClasses: 0,
              totalQuestions: 0,
              totalSubmissions: 0
            });
            setRecentSubmissions([]);
          }
        } else {
          // Fetch student stats
          try {
            const studentClassesResponse = await axios.get(
              getApiUrl(API_ENDPOINTS.STUDENT.CLASSES(user.userId)),
              { headers: getAuthHeaders() }
            );

            const studentSubmissionsResponse = await axios.get(
              getApiUrl(API_ENDPOINTS.STUDENT.SUBMISSIONS),
              { headers: getAuthHeaders() }
            );

            // Update stats with correct classes count
            const submissionsData = studentSubmissionsResponse.data.data || [];
            const classesData = studentClassesResponse.data.classes || [];
            
            setStats({
              activeClasses: classesData.length,
              totalSubmissions: submissionsData.length || 0
            });

            // Set recent submissions for student
            if (Array.isArray(submissionsData) && submissionsData.length > 0) {
              const recentOnes = submissionsData
                .sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt))
                .slice(0, 3)
                .map(submission => ({
                  _id: submission._id,
                  submittedAt: submission.submittedAt,
                  classId: submission.classId,
                  totalScore: submission.totalScore,
                  submissions: submission.submissions
                }));
              setRecentSubmissions(recentOnes);
            }
          } catch (err) {
            console.error('Error fetching student stats:', err);
            // Set default values instead of showing error
            setStats({
              activeClasses: 0,
              totalSubmissions: 0
            });
            setRecentSubmissions([]);
          }
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        // Set default values instead of showing error
        setStats({
          activeClasses: 0,
          totalQuestions: 0,
          totalSubmissions: 0
        });
        setRecentSubmissions([]);
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return <div className="loading">Loading dashboard data...</div>;
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getClassesPath = () => {
    if (userRole?.toLowerCase() === 'teacher') {
      return '/classes';
    }
    return '/my-classes'; // for students
  };

  const getSubmissionsPath = () => {
    if (userRole?.toLowerCase() === 'teacher') {
      return '/submissions';
    }
    return '/my-submissions'; // for students
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1>Dashboard</h1>
      </div>

      <div className="stats-grid">
        <div 
          className="stat-card" 
          onClick={() => navigate(getClassesPath())}
          style={{ cursor: 'pointer' }}
        >
          <h2>Active Classes</h2>
          <div className="number">{stats.activeClasses}</div>
        </div>
        {userRole?.toLowerCase() === 'teacher' && (
          <div 
            className="stat-card"
            onClick={() => navigate('/view-questions')}
            style={{ cursor: 'pointer' }}
          >
            <h2>Total Questions</h2>
            <div className="number">{stats.totalQuestions}</div>
          </div>
        )}
        <div 
          className="stat-card"
          onClick={() => navigate(getSubmissionsPath())}
          style={{ cursor: 'pointer' }}
        >
          <h2>Total Submissions</h2>
          <div className="number">{stats.totalSubmissions}</div>
        </div>
      </div>

      <div className="recent-section">
        <div className="section-header">
          <h2>Recent Submissions</h2>
          <Link to={getSubmissionsPath()} className="view-all-link">View All</Link>
        </div>

        {recentSubmissions.length === 0 ? (
          <div className="no-submissions">No recent submissions</div>
        ) : (
          <div className="submissions-list">
            {recentSubmissions.map((submission) => (
              <div 
                key={submission._id} 
                className="submission-card"
                onClick={() => {
                  navigate('/submissions');
                  setTimeout(() => {
                    const event = new CustomEvent('viewSubmissionDetails', { 
                      detail: { submissionId: submission._id }
                    });
                    window.dispatchEvent(event);
                  }, 100);
                }}
              >
                <div className="submission-header">
                  <div className="class-name">
                    Class: {submission.class?.name || submission.classId?.name || 'Unknown Class'}
                  </div>
                  {userRole?.toLowerCase() === 'teacher' && (
                    <div className="student-name">
                      {submission.student?.name || submission.studentId?.name || 'Unknown Student'}
                    </div>
                  )}
                  <div className="submission-date">
                    {new Date(submission.submittedAt).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })} ({new Date(submission.submittedAt).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false
                    })})
                  </div>
                </div>
                <div className="submission-score">
                  Score: {submission.totalScore || '0'}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {userRole?.toLowerCase() === 'teacher' && (
        <div className="action-buttons">
          <button 
            onClick={() => navigate('/insert-question')} 
            className="action-button"
          >
            Add Question
          </button>
          <button 
            onClick={() => navigate('/view-questions')} 
            className="action-button"
          >
            View Questions
          </button>
          <button 
            onClick={() => navigate('/create-class')} 
            className="action-button"
          >
            Create Class
          </button>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import "./Login.css";
import snapgradeLogo from '../../img/snapgrade-logo.png';
import { getApiUrl, API_ENDPOINTS } from '../../config/api.config';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (location.state?.error) {
      setError(location.state.error);
    }
  }, [location.state]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear any previous errors
    
    try {
      console.log('Sending login request with:', formData);
      
      const response = await fetch(getApiUrl(API_ENDPOINTS.LOGIN), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      
      const result = await response.json();
      console.log('Login response:', result);
      
      if (response.ok) {
        // Store complete user data including school
        localStorage.setItem('user', JSON.stringify({
          name: result.message.split(", ")[1],
          role: result.role,
          userId: result.userId,
          school: result.school.name
        }));

        // Store tokens separately
        localStorage.setItem("token", result.token);
        localStorage.setItem("refreshToken", result.refreshToken);
        
        // Navigate based on role
        if (result.role === 'admin') {
          navigate("/admin-dashboard");
        } else {
          navigate("/dashboard");
        }
      } else {
        // Show the specific error from the backend
        setError(result.message || result.error || "Invalid username or password");
        console.error('Login failed:', result);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError("An error occurred. Please check your connection and try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="left-section">
        <img src={snapgradeLogo} alt="SnapGrade Logo" className="logo" />
      </div>
      <div className="right-section">
        <div className="login-form-container">
          <h1>Welcome back</h1>
          <p className="subtitle">Enter your credentials to access your account</p>
          
          {error && <div className="error-message">{error}</div>}
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            
            
            <div className="form-group">
              <div className="password-header">
                <label>Password</label>
                {/*<Link to="/login" className="forgot-password">Forgot password?</Link>*/}
              </div>
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <button type="submit" className="sign-in-button">
              Sign in
            </button>

            <p className="signup-prompt">
              Don't have an account? <Link to="/register" className="signup-link">Sign up</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

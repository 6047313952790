import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CreateClass.css';

const CreateClass = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [className, setClassName] = useState('');
  const [students, setStudents] = useState([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const response = await axios.get(
        'https://backend-master-v8ob.onrender.com/api/v1/stem@home/teacher/school-students',
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setStudents(response.data.data || []);
    } catch (error) {
      console.error("Error fetching students:", error);
      setError("Failed to load students");
    }
  };

  const handleStudentSelection = (studentId) => {
    setSelectedStudentIds(prev => {
      if (prev.includes(studentId)) {
        return prev.filter(id => id !== studentId);
      } else {
        return [...prev, studentId];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://backend-master-v8ob.onrender.com/api/v1/stem@home/class/create',
        { 
          name: className,
          students: selectedStudentIds
        },
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (response.data.status === "success") {
        window.alert('Class created successfully!');
        navigate('/classes');
      } else {
        setError('Failed to create class');
      }
    } catch (err) {
      console.error("Error creating class:", err);
      setError(err.response?.data?.message || 'Failed to create class');
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (currentStep === 1 && className.trim()) {
      setCurrentStep(2);
    }
  };

  const handleBack = () => {
    setCurrentStep(1);
  };

  return (
    <div className="container">
      <div className="header">
        <h1>Create New Class</h1>
      </div>

      <div className="steps-indicator">
        <div className={`step ${currentStep >= 1 ? 'active' : ''}`}>1. Class Info</div>
        <div className={`step ${currentStep >= 2 ? 'active' : ''}`}>2. Add Students</div>
      </div>

      <div className="form-container">
        {currentStep === 1 ? (
          <div className="step-content">
            {error && <div className="error-message">{error}</div>}
            <div className="form-group">
              <label htmlFor="className">Class Name</label>
              <input
                type="text"
                id="className"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                placeholder="Enter class name"
                required
              />
            </div>
            <div className="button-group">
              <button 
                className="cancel-button"
                onClick={() => navigate('/classes')}
              >
                Cancel
              </button>
              <button 
                className="next-button"
                onClick={handleNext}
                disabled={!className.trim()}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className="step-content">
            <div className="students-list">
              {students.map((student) => (
                <div 
                  key={student.id} 
                  className={`student-item ${selectedStudentIds.includes(student.id) ? 'selected' : ''}`}
                  onClick={() => handleStudentSelection(student.id)}
                >
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={selectedStudentIds.includes(student.id)}
                      onChange={() => handleStudentSelection(student.id)}
                    />
                    <div>
                      <div className="student-name">{student.name}</div>
                      <div className="student-email">{student.email}</div>
                      <div className="student-school">{student.school}</div>
                    </div>
                  </label>
                </div>
              ))}
              {students.length === 0 && (
                <div className="no-students">
                  No students available
                </div>
              )}
            </div>
            <div className="button-group">
              <button 
                type="button" 
                className="back-button"
                onClick={handleBack}
              >
                Back
              </button>
              <button 
                type="button" 
                className="submit-button"
                onClick={handleSubmit}
                disabled={loading || selectedStudentIds.length === 0}
              >
                {loading ? 'Creating...' : 'Create Class'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateClass; 
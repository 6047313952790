import React, { useState } from 'react';
import './HelpPopup.css';

const HelpPopup = ({ isOpen, onClose, userRole }) => {
  const [activeSection, setActiveSection] = useState('overview');

  if (!isOpen) return null;

  const studentSections = {
    overview: {
      title: 'Overview',
      content: [
        'Welcome to the STEM@Home Student Dashboard! This platform helps you access your classes and submit your work.',
        'Use the sidebar navigation to explore different sections and access your classes.',
        'Each section provides specific features to help you with your learning journey.'
      ]
    },
    classes: {
      title: 'My Classes',
      content: [
        'View all your enrolled classes in the Classes section',
        'Click on a class to view assigned questions',
        'Access class materials and assignments',
        'Track your progress in each class'
      ]
    },
    submissions: {
      title: 'My Submissions',
      content: [
        'View all your submitted work in the Submissions section',
        'Check your scores and feedback',
        'Track your submission history',
        'Review past submissions to help improve'
      ]
    },
    tips: {
      title: 'Quick Tips',
      content: [
        'Regularly check your dashboard for new assignments',
        'Submit your work on time',
        'Review feedback to improve your performance',
        'Reach out to your teacher if you need help'
      ]
    }
  };

  const teacherSections = {
    overview: {
      title: 'Overview',
      content: [
        'Welcome to the STEM@Home Teacher Dashboard! This platform helps you manage your classes, create questions, and track student progress.',
        'Use the sidebar navigation to explore different help topics and learn about the platform\'s features.',
        'Each section provides detailed guidance on specific functionality to help you make the most of the platform.'
      ]
    },
    classes: {
      title: 'Managing Classes',
      content: [
        'Create a new class by clicking "Add New Class" on the Classes page',
        'View and manage your classes from the "Classes" tab',
        'Add or remove students from your classes at any time',
        'Click on a class name to view detailed information'
      ]
    },
    questions: {
      title: 'Questions Management',
      content: [
        'Create new questions from the "Questions" tab',
        'Set mark ranges and rubrics for accurate grading',
        'Assign questions to specific classes',
        'Edit or remove questions as needed'
      ]
    },
    submissions: {
      title: 'Viewing Submissions',
      content: [
        'Access all student submissions from the "Submissions" tab',
        'Filter submissions by class, student, or date',
        'View detailed answers and scores for each submission',
        'Track student progress and performance'
      ]
    }
  };

  const adminSections = {
    overview: {
      title: 'Overview',
      content: [
        'Welcome to the STEM@Home Admin Dashboard!',
        'Manage users, classes, and system settings',
        'Monitor overall system performance',
        'Access administrative tools and reports'
      ]
    },
    users: {
      title: 'Managing Users',
      content: [
        'View and manage all users',
        'Add new users to the system',
        'Modify user roles and permissions',
        'Monitor user activity'
      ]
    },
    classes: {
      title: 'Classes Overview',
      content: [
        'View all classes in the system',
        'Monitor class activities',
        'Manage class assignments',
        'Track class performance'
      ]
    },
    settings: {
      title: 'System Settings',
      content: [
        'Configure system preferences',
        'Manage global settings',
        'Update system parameters',
        'Monitor system health'
      ]
    }
  };

  const getSections = () => {
    const role = userRole?.toLowerCase();
    switch (role) {
      case 'student':
        return studentSections;
      case 'teacher':
        return teacherSections;
      case 'admin':
        return adminSections;
      default:
        return studentSections;
    }
  };

  const sections = getSections();

  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="popup-header">
          <h2>{userRole?.charAt(0).toUpperCase() + userRole?.slice(1)} Help Center</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="popup-content">
          <div className="help-container">
            <div className="help-sidebar">
              {Object.entries(sections).map(([key, section]) => (
                <button
                  key={key}
                  className={`help-section-btn ${activeSection === key ? 'active' : ''}`}
                  onClick={() => setActiveSection(key)}
                >
                  {section.title}
                </button>
              ))}
            </div>
            <div className="help-main">
              <h3>{sections[activeSection].title}</h3>
              <ul>
                {sections[activeSection].content.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPopup; 
import React from 'react';
import './SettingsPopup.scss';

const SettingsPopup = ({ show, onHide, userName, userRole, schoolName, schoolLogo }) => {
  if (!show) return null;

  return (
    <div className="settings-popup" onClick={onHide}>
      <div className="settings-content" onClick={e => e.stopPropagation()}>
        <div className="settings-header">
          <h2>User Settings</h2>
          <button className="close-button" onClick={onHide}>×</button>
        </div>
        
        <div className="settings-body">
          {schoolLogo && (
            <div className="school-logo-container">
              <img src={schoolLogo} alt="School Logo" className="school-logo" />
            </div>
          )}
          
          <div className="user-info">
            <div className="info-group">
              <label>Name</label>
              <p>{userName}</p>
            </div>
            
            <div className="info-group">
              <label>Role</label>
              <p className="role-badge" style={{ 
                backgroundColor: 
                  userRole?.toLowerCase() === 'student' ? '#4ade80' : 
                  userRole?.toLowerCase() === 'teacher' ? '#60a5fa' : 
                  userRole?.toLowerCase() === 'admin' ? '#f87171' : '#9ca3af'
              }}>
                {userRole?.toLowerCase() === 'student' ? 'Student' :
                 userRole?.toLowerCase() === 'teacher' ? 'Teacher' :
                 userRole?.toLowerCase() === 'admin' ? 'Admin' : userRole}
              </p>
            </div>
            
            <div className="info-group">
              <label>School</label>
              <p>{schoolName}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup; 
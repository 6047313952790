import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./Signup.css";
import snapgradeLogo from '../../img/snapgrade-logo.png';
// Import all school logos
import brentwoodLogo from '../../img/Brentwood-School.png';
import ascendLogo from '../../img/Ascend-Learning-Trust.png';
import caldayLogo from '../../img/Calday-Grammar-School.png';
import meridianLogo from '../../img/Meridian-Trust.png';
import { FiEye, FiEyeOff } from 'react-icons/fi';

// School name to logo mapping
const schoolLogos = {
  'Brentwood School': brentwoodLogo,
  'Ascend Learning Trust': ascendLogo,
  'Calday Grammar School': caldayLogo,
  'Meridian Trust': meridianLogo
};

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "student",
    schoolCode: ""
  });
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");
  const [schoolCodeError, setSchoolCodeError] = useState("");
  const [schoolCodeParts, setSchoolCodeParts] = useState(['', '', '', '']);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'schoolCode') {
      const upperValue = value.toUpperCase().slice(0, 16);
      setFormData({
        ...formData,
        [name]: upperValue
      });
      setSchoolCodeError("");
      if (upperValue.length === 16) {
        validateSchoolCode(upperValue);
      } else if (upperValue.length > 0 && upperValue.length < 16) {
        setSchoolCodeError("School code must be 16 characters");
        setSchoolName("");
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const validateSchoolCode = async (code) => {
    setIsLoading(true);
    setSchoolCodeError("");
    setSchoolLogo("");
    
    try {
      const response = await fetch("https://backend-master-v8ob.onrender.com/api/v1/stem@home/auth/validate-school", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          schoolCode: code 
        })
      });

      const data = await response.json();
      console.log('Validation response:', data);

      if (data.isValid) {
        setSchoolName(data.schoolName);
        // Set the logo based on the school name
        const logoPath = schoolLogos[data.schoolName];
        if (logoPath) {
          setSchoolLogo(logoPath);
        }
        setSchoolCodeError("");
      } else {
        setSchoolCodeError("Invalid school code");
        setSchoolName("");
        setSchoolLogo("");
      }
    } catch (error) {
      console.error('Validation error:', error);
      setSchoolCodeError("Error validating school code");
      setSchoolName("");
      setSchoolLogo("");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSchoolCodeChange = (index, value) => {
    // Convert to uppercase and remove non-alphanumeric characters
    value = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    
    // Limit to 4 characters
    value = value.slice(0, 4);

    // Update the specific part
    const newParts = [...schoolCodeParts];
    newParts[index] = value;
    setSchoolCodeParts(newParts);

    // Update the main form data with the complete code
    const completeCode = newParts.join('');
    setFormData(prev => ({
      ...prev,
      schoolCode: completeCode
    }));

    // Auto-focus next input if current is filled
    if (value.length === 4 && index < 3) {
      const nextInput = document.querySelector(`input[name="schoolCode${index + 1}"]`);
      if (nextInput) nextInput.focus();
    }

    // Validate if we have a complete code
    if (completeCode.length === 16) {
      validateSchoolCode(completeCode);
    } else {
      setSchoolCodeError("");
      setSchoolName("");
    }
  };

  const handleSchoolCodeKeyDown = (index, e) => {
    // Handle backspace
    if (e.key === 'Backspace' && !schoolCodeParts[index] && index > 0) {
      const prevInput = document.querySelector(`input[name="schoolCode${index - 1}"]`);
      if (prevInput) prevInput.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Validate school code
    if (!formData.schoolCode) {
      setSchoolCodeError("School code is required");
      return;
    }
    if (formData.schoolCode.length !== 16) {
      setSchoolCodeError("School code must be 16 characters");
      return;
    }
    if (!schoolName) {
      setSchoolCodeError("Please enter a valid school code");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch("https://backend-master-v8ob.onrender.com/api/v1/stem@home/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        navigate("/login");
      } else {
        setError(data.message || "Registration failed");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="left-section">
        <div className="logo-container">
          <img src={snapgradeLogo} alt="SnapGrade" className="logo" />
          {schoolName && (
            <img src={schoolLogos[schoolName]} alt={schoolName} className="school-logo" />
          )}
        </div>
      </div>
      <div className="right-section">
        <div className="signup-form-container">
          <div className="header-section">
            <h1 className="signup-title">Create an account</h1>
            <p className="subtitle">Join SnapGrade to start your learning journey</p>
          </div>

          {error && <div className="error-message">{error}</div>}

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Full Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your full name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>

            <div className="form-group">
              <label>School Code</label>
              <div className="school-code-container">
                {schoolCodeParts.map((part, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <span className="school-code-separator">-</span>}
                    <input
                      type="text"
                      name={`schoolCode${index}`}
                      value={part}
                      onChange={(e) => handleSchoolCodeChange(index, e.target.value)}
                      onKeyDown={(e) => handleSchoolCodeKeyDown(index, e)}
                      className={`school-code-input ${schoolCodeError ? 'error' : ''}`}
                      placeholder="XXXX"
                      maxLength={4}
                      required
                    />
                  </React.Fragment>
                ))}
              </div>
              <p className="helper-text">Please enter the 16-digit school code provided by your institution</p>
              {schoolCodeError && <p className="error-text">{schoolCodeError}</p>}
              {schoolName && <p className="success-text">School: {schoolName}</p>}
            </div>

            <div className="form-group">
              <label>Password</label>
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Create a password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
                <button
                  type="button"
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </button>
              </div>
            </div>

            <button 
              type="submit" 
              className="create-account-button"
              disabled={isLoading || !!schoolCodeError}
            >
              {isLoading ? "Creating Account..." : "Create Account"}
            </button>
          </form>

          <p className="login-prompt">
            Already have an account? <Link to="/login" className="login-link">Sign in</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
import React, { useState, useEffect } from "react";
import "./rubricList.styles.scss";

const RubricList = () => {
  const [rubrics, setRubrics] = useState([]);

  // Simulated fetch rubrics (replace with API call)
  useEffect(() => {
    const fetchRubrics = async () => {
      // Simulate API data
      const mockRubrics = [
        { id: 1, question: "What is AI?", answer: "Artificial Intelligence..." },
        { id: 2, question: "Explain photosynthesis.", answer: "Photosynthesis is..." },
      ];
      setRubrics(mockRubrics);
    };

    fetchRubrics();
  }, []);

  return (
    <div className="rubric-list-page">
      <h1>Rubric List</h1>
      {rubrics.length === 0 ? (
        <p>No rubrics available. Add some!</p>
      ) : (
        <ul>
          {rubrics.map((rubric) => (
            <li key={rubric.id}>
              <h2>{rubric.question}</h2>
              <p>{rubric.answer}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RubricList;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const EditQuestion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState('');
  const [marks, setMarks] = useState('');
  const [lowMarkRange, setLowMarkRange] = useState('');
  const [highMarkRange, setHighMarkRange] = useState('');
  const [rubric, setRubric] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuestionDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/questions/${id}`,
          { headers: getAuthHeaders() }
        );

        const questionData = response.data.data;
        setQuestion(questionData.question);
        setMarks(questionData.marks);
        setLowMarkRange(questionData.lowMarkRange);
        setHighMarkRange(questionData.highMarkRange);
        setRubric(questionData.rubric || '');
        setLoading(false);
      } catch (err) {
        console.error('Error fetching question:', err);
        setError('Failed to load question details');
        setLoading(false);
      }
    };

    fetchQuestionDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/questions/${id}`,
        {
          question,
          marks,
          lowMarkRange,
          highMarkRange,
          rubric
        },
        { headers: getAuthHeaders() }
      );

      if (response.data.status === 'success') {
        navigate('/view-questions');
      }
    } catch (err) {
      console.error('Error updating question:', err);
      setError('Failed to update question');
    }
  };

  if (loading) {
    return <div style={styles.loading}>Loading question details...</div>;
  }

  if (error) {
    return <div style={styles.error}>{error}</div>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Edit Question</h1>
      <form onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label>Question:</label>
          <textarea
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Type the question here"
            required
            style={styles.textarea}
          />
        </div>

        <div style={styles.formGroup}>
          <label>Marks:</label>
          <input
            type="number"
            value={marks}
            onChange={(e) => setMarks(e.target.value)}
            placeholder="Questions maximum marks"
            required
            style={styles.input}
          />
        </div>

        <div style={styles.markRanges}>
          <div style={styles.formGroup}>
            <label>Low Mark Range: <span style={styles.formatHint}>(format: start-end)</span></label>
            <input
              type="text"
              value={lowMarkRange}
              onChange={(e) => setLowMarkRange(e.target.value)}
              placeholder="e.g. 0-3"
              required
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label>High Mark Range: <span style={styles.formatHint}>(format: start-end)</span></label>
            <input
              type="text"
              value={highMarkRange}
              onChange={(e) => setHighMarkRange(e.target.value)}
              placeholder="e.g. 4-6"
              required
              style={styles.input}
            />
          </div>
        </div>

        <div style={styles.formGroup}>
          <label>Rubric:</label>
          <textarea
            value={rubric}
            onChange={(e) => setRubric(e.target.value)}
            placeholder="Write the rubric for the question"
            style={styles.rubricTextarea}
          />
        </div>

        <button type="submit" style={styles.saveButton}>
          Save Question
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  title: {
    color: '#333',
    marginBottom: '2rem',
    textAlign: 'center',
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  input: {
    width: '100%',
    padding: '0.5rem',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '1rem',
  },
  textarea: {
    width: '100%',
    minHeight: '150px',
    padding: '0.5rem',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '1rem',
    resize: 'vertical',
    lineHeight: '1.5',
    whiteSpace: 'pre-wrap',
  },
  rubricTextarea: {
    width: '100%',
    minHeight: '400px',
    padding: '0.5rem',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '1rem',
    resize: 'vertical',
    lineHeight: '1.5',
    whiteSpace: 'pre-wrap',
  },
  markRanges: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1.5rem',
  },
  formatHint: {
    fontSize: '0.85rem',
    color: '#666',
  },
  saveButton: {
    width: '100%',
    padding: '0.75rem',
    backgroundColor: '#f2d13e',
    color: '#000',
    border: 'none',
    borderRadius: '4px',
    fontSize: '1rem',
    cursor: 'pointer',
    marginTop: '1rem',
    fontWeight: '500',
  },
  loading: {
    textAlign: 'center',
    padding: '2rem',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    padding: '2rem',
  },
};

export default EditQuestion;

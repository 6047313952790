import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

const InsertNewQuestion = () => {
  const [question, setQuestion] = useState("");
  const [marks, setMarks] = useState("");
  const [highMarkRange, setHighMarkRange] = useState("");
  const [lowMarkRange, setLowMarkRange] = useState("");
  const [rubric, setRubric] = useState({ low: "", high: "" });
  const [useAI, setUseAI] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [customFeedback, setCustomFeedback] = useState("");
  const [rangeError, setRangeError] = useState("");
  const [rubricGenerated, setRubricGenerated] = useState(false);
  const [rubricMode, setRubricMode] = useState('ai'); // 'ai' or 'manual'

  const questionTextareaRef = useRef(null);
  const lowRubricRef = useRef(null);
  const highRubricRef = useRef(null);

  const adjustTextareaHeight = (ref) => {
    const textarea = ref.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    adjustTextareaHeight(questionTextareaRef);
  }, [question]);

  useEffect(() => {
    if (lowRubricRef.current) {
      adjustTextareaHeight(lowRubricRef);
    }
  }, [rubric.low]);

  useEffect(() => {
    if (highRubricRef.current) {
      adjustTextareaHeight(highRubricRef);
    }
  }, [rubric.high]);

  const calculateMarkRanges = (totalMarks) => {
    const marks = parseInt(totalMarks);
    if (!marks || marks < 1) return;

    if (marks === 1) {
      setLowMarkRange("0-0");
      setHighMarkRange("1-1");
    } else if (marks === 2) {
      setLowMarkRange("0-1");
      setHighMarkRange("2-2");
    } else {
      const lowEnd = Math.floor(marks * 0.3);
      const midPoint = Math.ceil(marks * 0.6);
      setLowMarkRange(`0-${lowEnd}`);
      setHighMarkRange(`${midPoint}-${marks}`);
    }
    setRangeError("");
  };

  const handleGenerateRubric = async () => {
    // Check for empty fields first
    if (!question || !marks || !highMarkRange || !lowMarkRange) {
      setError("Please provide all required details before generating a rubric.");
      return;
    }

    // Validate ranges
    if (!validateRangeBounds(lowMarkRange, true)) {
      setError(`Invalid low mark range. Must be between 0 and ${marks-1} (for ${marks} marks), and start number must be less than end number.`);
      return;
    }

    if (!validateRangeBounds(highMarkRange, false)) {
      setError(`Invalid high mark range. Must be between 1 and ${marks} (for ${marks} marks), and start number must be less than end number.`);
      return;
    }

    // Check if ranges overlap
    const lowParts = lowMarkRange.split('-').map(num => parseInt(num));
    const highParts = highMarkRange.split('-').map(num => parseInt(num));
    if (lowParts[1] >= highParts[0]) {
      setError("Low mark range and high mark range cannot overlap.");
      return;
    }

    // If all validations pass, proceed with generation
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        "https://backend-master-v8ob.onrender.com/api/anthropic",
        {
          messages: [
            {
              role: "user",
              content: `Create two separate marking rubrics for a question worth ${marks} marks:
              1. A rubric for the low mark range (${lowMarkRange}) that describes what a lower-achieving response would look like
              2. A rubric for the high mark range (${highMarkRange}) that describes what a high-achieving response would look like
              
              The question is: "${question}"`
            }
          ],
          temperature: 0.7,
          max_tokens: 1000
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const fullRubric = response.data.message || "";
      
      // Split the rubric into low and high sections
      const sections = fullRubric.split(/(?=Low mark range|High mark range)/i);
      const lowSection = sections.find(s => /low mark range/i.test(s)) || "";
      const highSection = sections.find(s => /high mark range/i.test(s)) || "";
      
      setRubric({
        low: lowSection.replace(/^Low mark range[^:]*:/i, "").trim(),
        high: highSection.replace(/^High mark range[^:]*:/i, "").trim()
      });
      setRubricGenerated(true);
    } catch (err) {
      setError("Failed to generate rubric. Please try again.");
      console.error("Error generating rubric:", err);
      setRubricGenerated(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if all required fields are filled
    if (!question || !marks || !highMarkRange || !lowMarkRange) {
      setError("Please provide all required details before saving.");
      return;
    }

    // Validate ranges
    if (!validateRangeBounds(lowMarkRange, true)) {
      setError(`Invalid low mark range. Must be between 0 and ${marks-1} (for ${marks} marks), and start number must be less than end number.`);
      return;
    }

    if (!validateRangeBounds(highMarkRange, false)) {
      setError(`Invalid high mark range. Must be between 1 and ${marks} (for ${marks} marks), and start number must be less than end number.`);
      return;
    }

    // Check if ranges overlap
    const lowParts = lowMarkRange.split('-').map(num => parseInt(num));
    const highParts = highMarkRange.split('-').map(num => parseInt(num));
    if (lowParts[1] >= highParts[0]) {
      setError("Low mark range and high mark range cannot overlap.");
      return;
    }

    // Check if rubric is complete based on mode
    if (rubricMode === 'manual') {
      if (!rubric.low.trim() || !rubric.high.trim()) {
        setError("Please provide both low and high mark range rubrics before saving.");
        return;
      }
    } else if (!rubricGenerated) {
      setError("Please generate a rubric before saving the question.");
      return;
    }

    try {
      const teacherId = localStorage.getItem('userId');
      console.log('TeacherId from localStorage:', teacherId);

      // Format the rubric as a single string with clear sections
      const formattedRubric = `Low Mark Range (${lowMarkRange}):\n${rubric.low}\n\nHigh Mark Range (${highMarkRange}):\n${rubric.high}`;

      const payload = {
        teacherId,
        question,
        marks: parseInt(marks),
        highMarkRange,
        lowMarkRange,
        rubric: formattedRubric,
        createdAt: new Date()
      };

      console.log('Sending payload:', payload);
      console.log('Token:', localStorage.getItem('token'));

      const response = await axios.post(
        "https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/questions/create",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          },
        }
      );

      console.log('Response:', response);

      if (response.status === 201) {
        alert("Question saved successfully!");
        resetForm();
      }
    } catch (err) {
      console.error("Full error object:", err);
      console.error("Error response data:", err.response?.data);
      console.error("Error status:", err.response?.status);
      setError(`Failed to save question: ${err.response?.data?.message || err.message}`);
    }
  };

  const resetForm = () => {
    setQuestion("");
    setMarks("");
    setHighMarkRange("");
    setLowMarkRange("");
    setRubric({ low: "", high: "" });
    setFeedbackType("");
    setCustomFeedback("");
    setRubricGenerated(false);
  };

  const containerStyle = {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  };

  const inputContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "1rem",
  };

  const inputStyle = {
    width: "100%",
    padding: "12px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1rem",
    "@media (max-width: 768px)": {
      padding: "10px",
    },
  };

  const textareaStyle = {
    ...inputStyle,
    minHeight: "120px",
    height: "auto",
    overflow: "hidden",
    resize: "none",
    lineHeight: "1.5",
    fontFamily: "inherit"
  };

  const buttonStyle = {
    padding: "12px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "1rem",
    transition: "background-color 0.2s",
    "@media (max-width: 768px)": {
      padding: "10px 16px",
    },
  };

  const primaryButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#007bff",
    color: "white",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
  };

  const successButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#28a745",
    color: "white",
    "&:hover": {
      backgroundColor: "#218838",
    },
  };

  const validateNumberInput = (value) => {
    return /^\d*$/.test(value);
  };

  const validateRangeInput = (value) => {
    return /^[\d-]*$/.test(value);
  };

  const validateRangeBounds = (range, isLow) => {
    if (!marks) return false;
    const maxMarks = parseInt(marks);
    
    // Check if range has correct format (e.g., "0-2" or "4-6")
    const parts = range.split('-');
    if (parts.length !== 2) return false;
    
    const start = parseInt(parts[0]);
    const end = parseInt(parts[1]);
    
    // Check if numbers are valid
    if (isNaN(start) || isNaN(end)) return false;
    
    // Check bounds
    if (start < 0 || end > maxMarks || start >= end) return false;
    
    // Check bounds
    if (isLow && (start < 0 || end >= maxMarks)) return false;
    
    // For high range: must be between 1 and maxMarks
    if (!isLow && (start <= 0 || end > maxMarks)) return false;
    
    return true;
  };

  const handleMarksChange = (e) => {
    const value = e.target.value;
    if (validateNumberInput(value)) {
      setMarks(value);
      calculateMarkRanges(value);
      setRangeError("");
    }
  };

  const handleLowRangeChange = (e) => {
    const value = e.target.value;
    if (validateRangeInput(value)) {
      setLowMarkRange(value);
      // Only validate if we have a complete range format
      if (value.includes('-')) {
        if (!validateRangeBounds(value, true)) {
          setRangeError(`Low mark range must be between 0 and ${marks-1} (for ${marks} marks), and start number must be less than end number`);
        } else {
          setRangeError("");
        }
      }
    }
  };

  const handleHighRangeChange = (e) => {
    const value = e.target.value;
    if (validateRangeInput(value)) {
      setHighMarkRange(value);
      // Only validate if we have a complete range format
      if (value.includes('-')) {
        if (!validateRangeBounds(value, false)) {
          setRangeError(`High mark range must be between 1 and ${marks} (for ${marks} marks), and start number must be less than end number`);
        } else {
          setRangeError("");
        }
      }
    }
  };

  const toggleButtonContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginBottom: "20px",
    alignItems: "center"
  };

  const orDividerStyle = {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    margin: "10px 0",
    gap: "15px"
  };

  const lineStyle = {
    flex: 1,
    height: "1px",
    backgroundColor: "#dee2e6"
  };

  const orTextStyle = {
    color: "#6c757d",
    fontSize: "0.9rem",
    padding: "0 10px"
  };

  const actionButtonStyle = {
    ...buttonStyle,
    width: "100%",
    maxWidth: "400px",
    padding: "12px 20px",
    fontSize: "1rem",
    transition: "all 0.2s ease",
    border: "1px solid #dee2e6",
    "&:hover": {
      transform: "translateY(-1px)",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
    }
  };

  const manualButtonStyle = {
    ...actionButtonStyle,
    backgroundColor: rubricMode === 'manual' ? "#007bff" : "#f8f9fa",
    color: rubricMode === 'manual' ? "white" : "#495057",
    "&:hover": {
      ...actionButtonStyle["&:hover"],
      backgroundColor: rubricMode === 'manual' ? "#0056b3" : "#e9ecef"
    }
  };

  const aiButtonStyle = {
    ...actionButtonStyle,
    backgroundColor: rubricMode === 'ai' ? "#007bff" : "#f8f9fa",
    color: rubricMode === 'ai' ? "white" : "#495057",
    opacity: loading ? 0.7 : 1,
    cursor: loading ? "not-allowed" : "pointer",
    "&:hover": {
      ...actionButtonStyle["&:hover"],
      backgroundColor: rubricMode === 'ai' ? "#0056b3" : "#e9ecef"
    }
  };

  const handleSaveQuestion = async () => {
    try {
      // Get teacher ID from localStorage
      const userData = localStorage.getItem('user');
      const user = JSON.parse(userData);
      const teacherId = user.userId;

      // Format the rubric as a single string with clear sections
      const formattedRubric = `Low Mark Range (${lowMarkRange}):\n${rubric.low}\n\nHigh Mark Range (${highMarkRange}):\n${rubric.high}`;

      const response = await fetch('https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/questions/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          teacherId: teacherId,
          question: question,
          marks: parseInt(marks),
          highMarkRange: highMarkRange,
          lowMarkRange: lowMarkRange,
          rubric: formattedRubric
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save question');
      }

      // Question saved successfully
      setError('');
      alert('Question saved successfully!');
      
      // Clear form
      setQuestion('');
      setMarks('');
      setHighMarkRange('');
      setLowMarkRange('');
      setRubric({ low: "", high: "" });
      setRubricGenerated(false);
    } catch (error) {
      setError(error.message || 'Failed to save question');
    }
  };

  return (
    <div style={containerStyle}>
      <h2 style={{ marginBottom: "24px", fontSize: "1.75rem" }}>Insert New Question</h2>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSaveQuestion();
      }} style={formStyle}>
        <div style={inputContainerStyle}>
          <label style={labelStyle}>Question:</label>
          <textarea
            ref={questionTextareaRef}
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            placeholder="Type the question here"
            style={textareaStyle}
          />
        </div>

        <div style={inputContainerStyle}>
          <label style={labelStyle}>Marks:</label>
          <input
            type="text"
            inputMode="numeric"
            value={marks}
            onChange={handleMarksChange}
            placeholder="Questions maximum marks"
            style={inputStyle}
            min="1"
          />
        </div>

        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          <div style={{ ...inputContainerStyle, flex: 1, minWidth: "250px" }}>
            <label style={labelStyle}>
              Low Mark Range: <span style={{ fontWeight: "normal", fontSize: "0.9rem" }}>(format: start-end)</span>
            </label>
            <input
              type="text"
              value={lowMarkRange}
              onChange={handleLowRangeChange}
              placeholder="e.g. 0-3"
              style={{
                ...inputStyle,
                borderColor: rangeError && lowMarkRange.includes('-') ? '#dc3545' : '#ccc'
              }}
            />
          </div>

          <div style={{ ...inputContainerStyle, flex: 1, minWidth: "250px" }}>
            <label style={labelStyle}>
              High Mark Range: <span style={{ fontWeight: "normal", fontSize: "0.9rem" }}>(format: start-end)</span>
            </label>
            <input
              type="text"
              value={highMarkRange}
              onChange={handleHighRangeChange}
              placeholder="e.g. 4-6"
              style={{
                ...inputStyle,
                borderColor: rangeError && highMarkRange.includes('-') ? '#dc3545' : '#ccc'
              }}
            />
          </div>
        </div>

        {rangeError && (
          <div style={{ color: '#dc3545', fontSize: '0.9rem', marginTop: '5px' }}>
            {rangeError}
          </div>
        )}

        <div style={toggleButtonContainerStyle}>
          <button
            type="button"
            style={manualButtonStyle}
            onClick={() => {
              setRubricMode('manual');
              setRubricGenerated(true);
              setRubric({ low: "", high: "" });
            }}
          >
            Write Rubric Manually
          </button>

          <div style={orDividerStyle}>
            <div style={lineStyle}></div>
            <span style={orTextStyle}>or</span>
            <div style={lineStyle}></div>
          </div>

          <button
            type="button"
            style={aiButtonStyle}
            onClick={() => {
              setRubricMode('ai');
              handleGenerateRubric();
            }}
            disabled={loading}
          >
            {loading ? "Generating..." : "Generate Rubric with AI"}
          </button>
        </div>

        {rubricMode === 'manual' && (
          <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            <div style={{ flex: 1, minWidth: "300px" }}>
              <label style={{ ...labelStyle, display: "block", marginBottom: "8px" }}>
                Low Mark Range ({lowMarkRange}):
              </label>
              <textarea
                ref={lowRubricRef}
                value={rubric.low}
                onChange={(e) => {
                  setRubric(prev => ({ ...prev, low: e.target.value }));
                }}
                placeholder="Write the rubric for low mark range"
                style={{
                  ...textareaStyle,
                  minHeight: "120px",
                  width: "100%",
                  backgroundColor: "#fff",
                  height: "auto",
                  overflow: "hidden",
                  resize: "none",
                  lineHeight: "1.5"
                }}
              />
            </div>
            <div style={{ flex: 1, minWidth: "300px" }}>
              <label style={{ ...labelStyle, display: "block", marginBottom: "8px" }}>
                High Mark Range ({highMarkRange}):
              </label>
              <textarea
                ref={highRubricRef}
                value={rubric.high}
                onChange={(e) => {
                  setRubric(prev => ({ ...prev, high: e.target.value }));
                }}
                placeholder="Write the rubric for high mark range"
                style={{
                  ...textareaStyle,
                  minHeight: "120px",
                  width: "100%",
                  backgroundColor: "#fff",
                  height: "auto",
                  overflow: "hidden",
                  resize: "none",
                  lineHeight: "1.5"
                }}
              />
            </div>
          </div>
        )}

        {rubricMode === 'ai' && (rubric.low || rubric.high) && (
          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              <div style={{ flex: 1, minWidth: "300px" }}>
                <label style={{ ...labelStyle, display: "block", marginBottom: "8px" }}>
                  Low Mark Range ({lowMarkRange}): <span style={{ fontWeight: "normal", fontSize: "0.9rem" }}>(editable)</span>
                </label>
                <textarea
                  ref={lowRubricRef}
                  value={rubric.low}
                  onChange={(e) => {
                    setRubric(prev => ({ ...prev, low: e.target.value }));
                  }}
                  style={{
                    ...textareaStyle,
                    minHeight: "120px",
                    width: "100%",
                    backgroundColor: "#fff",
                    height: "auto",
                    overflow: "hidden",
                    resize: "none",
                    lineHeight: "1.5"
                  }}
                />
              </div>
              <div style={{ flex: 1, minWidth: "300px" }}>
                <label style={{ ...labelStyle, display: "block", marginBottom: "8px" }}>
                  High Mark Range ({highMarkRange}): <span style={{ fontWeight: "normal", fontSize: "0.9rem" }}>(editable)</span>
                </label>
                <textarea
                  ref={highRubricRef}
                  value={rubric.high}
                  onChange={(e) => {
                    setRubric(prev => ({ ...prev, high: e.target.value }));
                  }}
                  style={{
                    ...textareaStyle,
                    minHeight: "120px",
                    width: "100%",
                    backgroundColor: "#fff",
                    height: "auto",
                    overflow: "hidden",
                    resize: "none",
                    lineHeight: "1.5"
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {error && (
          <p style={{ color: "red", margin: "10px 0", fontSize: "0.9rem" }}>
            {error}
          </p>
        )}

        <button
          type="submit"
          style={{
            ...successButtonStyle,
            opacity: !rubricGenerated ? 0.5 : 1,
            cursor: !rubricGenerated ? "not-allowed" : "pointer",
          }}
          disabled={!rubricGenerated}
        >
          Save Question
        </button>
      </form>
    </div>
  );
};

export default InsertNewQuestion;

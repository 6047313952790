import React, { useEffect, useState, useRef } from "react";
import './AdminDashboard.css';
import axios from "axios";
import ButtonComponent from '../../components/ui/buttonComponent/button.component';
import Pagination from '../../components/ui/Pagination/pagination.component';
import refreshToken from '../../utils/apiUtils';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const AdminDashboard = () => {
  const [userName, setUserName] = useState('');
  const [className, setClassName] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [showTeacherList, setShowTeacherList] = useState(false);
  const [showStudentList, setShowStudentList] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [currentTeacherPage, setCurrentTeacherPage] = useState(1);
  const [currentStudentPage, setCurrentStudentPage] = useState(1);
  const [currentClassPage, setCurrentClassPage] = useState(1);
  const [currentView, setCurrentView] = useState(''); // Empty or 'createClass' or 'viewClasses'
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [dashboardStats, setDashboardStats] = useState({
    totalTeachers: 0,
    totalStudents: 0,
    totalClasses: 0
  });
  const [error, setError] = useState(null);
  
  const itemsPerPage = 10;
  const classDetailsRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      setUserName(user.name);
    }
    
    // Fetch initial data
    fetchTeachers();
    fetchStudents();
    fetchClasses();
  }, []);

  // Update dashboard stats whenever the data changes
  useEffect(() => {
    setDashboardStats({
      totalTeachers: teachers.length,
      totalStudents: students.length,
      totalClasses: classes.length
    });
  }, [teachers, students, classes]);

  // Function to handle API requests with token refresh
  const apiRequest = async (request) => {
    try {
      const response = await request();
      console.log("API Request Success:", response);
      return response;
    } catch (error) {
      console.error("API Request Error:", error.response || error);
      if (error.response && error.response.status === 401) {
        // Token expired, try refreshing
        const newToken = await refreshToken();
        if (newToken) {
          // Retry the request with the new token
          return await request();
        }
      }
      throw error;
    }
  };

  // Function to fetch students
  const fetchStudents = async () => {
    try {
      const response = await apiRequest(() => axios.get(
        getApiUrl(API_ENDPOINTS.STUDENT.ALL),
        { headers: getAuthHeaders() }
      ));
      setStudents(response.data.students);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  // Function to fetch teachers
  const fetchTeachers = async () => {
    try {
      const response = await apiRequest(() => axios.get(
        getApiUrl(API_ENDPOINTS.TEACHER.ALL),
        { headers: getAuthHeaders() }
      ));
      setTeachers(response.data.teachers);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  // Function to fetch all classes
  const fetchClasses = async () => {
    try {
      const response = await apiRequest(() => axios.get(
        getApiUrl(API_ENDPOINTS.CLASS.ALL),
        { headers: getAuthHeaders() }
      ));
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  // Handle the click of "Select Teacher" button
  const handleSelectTeacherClick = () => {
    if (className) {
      setShowTeacherList(true);
      fetchTeachers(); // Fetch teachers when the button is clicked
    } else {
      alert("Please enter a class name first.");
    }
  };

  // Handle the selection of a teacher
  const handleTeacherSelection = (teacher) => {
    setSelectedTeacher(teacher);
    setShowTeacherList(false); // Hide the teacher list after selection
    fetchStudents(); // Fetch students after selecting a teacher
    setShowStudentList(true); // Show the student list
  };

  // Handle student selection (toggle)
  const handleStudentSelection = (student) => {
    setSelectedStudents((prevSelected) => {
      if (prevSelected.includes(student._id)) {
        // Remove student if already selected
        return prevSelected.filter((id) => id !== student._id);
      } else {
        // Add student if not already selected
        return [...prevSelected, student._id];
      }
    });
  };

  // Handle confirmation of selected teacher and students
  const handleConfirmSelection = () => {
    if (selectedTeacher && selectedStudents.length > 0) {
      setShowConfirmation(true); // Show confirmation modal
    } else {
      alert("Please select a teacher and at least one student.");
    }
  };

  // Handle class creation with selected teacher and students
  const handleCreateClass = async () => {
    try {
      // Step 1: Create the class with the teacher
      const classResponse = await apiRequest(() => axios.post(
        getApiUrl(API_ENDPOINTS.CLASS.CREATE),
        {
          name: className,
          teacherId: selectedTeacher._id,
        },
        { headers: getAuthHeaders() }
      ));
  
      // Ensure the class is successfully created before proceeding
      if (classResponse.status === 201) {
        const classId = classResponse.data.class._id; // Get the class ID from the response
        console.log("Class created:", classResponse.data);

        // Step 2: Assign students to the created class if any are selected
        if (selectedStudents.length > 0) {
          const assignStudentsResponse = await apiRequest(() => axios.post(
            getApiUrl(API_ENDPOINTS.STUDENT.ASSIGN_TO_CLASS),
            {
              classId: classId,
              studentIds: selectedStudents
            },
            { headers: getAuthHeaders() }
          ));

          console.log("Students assigned to class:", assignStudentsResponse.data);
        }

        // Reset states after successful creation and assignment
        setClassName('');
        setSelectedTeacher(null);
        setSelectedStudents([]);
        setShowStudentList(false);
        setShowConfirmation(false);
        setShowSuccessMessage(true); // Show the success message
        setTimeout(() => setShowSuccessMessage(false), 3000); // Hide the message after 3 seconds
      } else {
        console.error("Error: Failed to create class.");
      }
    } catch (error) {
      console.error("Error creating class:", error);
      setError("Failed to create class");
    }
  };

  // Handle the view change
  const handleViewChange = (view) => {
    setCurrentView(view);
    if (view === 'viewClasses') {
      fetchClasses(); // Fetch classes when "View Classes" is clicked
    }
  };

  // Handle class selection
  const handleClassSelection = (cls) => {
    setSelectedClass(cls);
    // Scroll to the class details section
    if (classDetailsRef.current) {
      classDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Calculate total pages
  // const totalClassPages = Math.ceil(classes.length / itemsPerPage);
  // const totalTeacherPages = Math.ceil(teachers.length / itemsPerPage);
  // const totalStudentPages = Math.ceil(students.length / itemsPerPage);

  const handleTeacherCardClick = () => {
    navigate('/users', { state: { filterRole: 'teacher' } });
  };

  const handleStudentCardClick = () => {
    navigate('/users', { state: { filterRole: 'student' } });
  };

  const handleClassesCardClick = () => {
    navigate('/all-classes');
  };

  return (
    <div className="layout">
      <Sidebar userName={userName} role="admin" />
      <div className="main-content">
        <div className="dashboard-container">
          <div className="dashboard-header">
            <h1>Admin Dashboard</h1>
          </div>

          <div className="stats-grid">
            <div 
              className="stat-card" 
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                console.log('Teacher card clicked');
                navigate('/users', { state: { filterRole: 'teacher' } });
              }}
            >
              <h3>Total Teachers</h3>
              <p className="stat-number">{dashboardStats.totalTeachers}</p>
            </div>
            <div 
              className="stat-card" 
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                console.log('Student card clicked');
                navigate('/users', { state: { filterRole: 'student' } });
              }}
            >
              <h3>Total Students</h3>
              <p className="stat-number">{dashboardStats.totalStudents}</p>
            </div>
            <div 
              className="stat-card" 
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                console.log('Classes card clicked');
                navigate('/all-classes');
              }}
            >
              <h3>Total Classes</h3>
              <p className="stat-number">{dashboardStats.totalClasses}</p>
            </div>
          </div>

          <div className="recent-activity">
            <div className="recent-section">
              <div className="section-header">
                <h2>Recent Classes</h2>
                <button 
                  className="view-all-btn"
                  onClick={() => navigate('/all-classes')}
                >
                  View All
                </button>
              </div>
              <div className="recent-items">
                {classes.slice(0, 3).map((classItem) => (
                  <div key={classItem._id} className="recent-item" onClick={() => navigate(`/all-classes/${classItem._id}`)}>
                    <h3>{classItem.name}</h3>
                    <p>Teacher: {teachers[classItem.teacher] || 'Unknown Teacher'}</p>
                    <p>Students: {Array.isArray(classItem.students) ? classItem.students.length : 0}</p>
                  </div>
                ))}
                {classes.length === 0 && (
                  <div className="empty-state">
                    <p>No classes found</p>
                  </div>
                )}
              </div>
            </div>

            <div className="recent-section">
              <div className="section-header">
                <h2>Recent Submissions</h2>
                <button 
                  className="view-all-btn"
                  onClick={() => navigate('/all-submissions')}
                >
                  View All
                </button>
              </div>
              <div className="recent-items">
                <div className="empty-state">
                  <p>No submissions found</p>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-actions">
            <div className="action-buttons">
              <ButtonComponent
                onClick={() => navigate('/all-classes')}
                className="view-classes-btn"
              >
                View All Classes
              </ButtonComponent>
            </div>
          </div>
        </div>

        {showSuccessMessage && (
          <div className="success-modal">
            <div className="success-content">
              <p>Class created successfully!</p>
              <ButtonComponent onClick={() => setShowSuccessMessage(false)}>
                Close
              </ButtonComponent>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;

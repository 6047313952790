import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './StudentPage.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const MySubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubmissions();
  }, []);

  const fetchSubmissions = async () => {
    try {
      const response = await axios.get(
        getApiUrl(API_ENDPOINTS.SUBMISSION.LIST),
        { headers: getAuthHeaders() }
      );

      // The response has submissions directly in the data array
      if (response.data && Array.isArray(response.data)) {
        setSubmissions(response.data);
      } else if (response.data && Array.isArray(response.data.data)) {
        // Handle case where submissions are nested in data property
        setSubmissions(response.data.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setError('Failed to load submissions - unexpected data format');
      }
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setError(error.response?.data?.message || 'Failed to load submissions');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    return `${formattedDate} (${formattedTime})`;
  };

  if (loading) {
    return (
      <div className="submissions-container">
        <h1 className="page-title">My Submissions</h1>
        <div className="loading-message">Loading submissions...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="submissions-container">
        <h1 className="page-title">My Submissions</h1>
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="submissions-container">
      <h1 className="page-title">My Submissions</h1>
      
      <div className="submissions-list">
        {submissions.map((submission) => (
          <div key={submission._id} className="submission-card" 
               onClick={() => navigate(`/my-submissions/${submission._id}`, { 
                 state: { 
                   submissionId: submission._id,
                   className: submission.classId.name,
                   gradingPending: submission.status === 'pending'
                 }
               })}>
            <div className="submission-header">
              <h3>{submission.classId.name}</h3>
              <span className="submission-date">
                {formatDate(submission.submittedAt)}
              </span>
            </div>
            <div className="submission-details">
              <div className="score-info">
                <span className="score-label">Score:</span>
                <span className="score-value">
                  {submission.status === 'pending' ? 'Pending' : `${submission.totalScore}`}
                </span>
              </div>
            </div>
          </div>
        ))}
        
        {submissions.length === 0 && (
          <div className="no-submissions">
            No submissions yet
          </div>
        )}
      </div>
    </div>
  );
};

export default MySubmissions; 
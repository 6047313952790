import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './StartSubmission.css';
import axios from 'axios';

const StartSubmission = () => {
  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role === 'teacher') {
      navigate('/dashboard');
      return;
    }
    fetchClasses();
  }, [navigate]);

  const fetchClasses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://backend-master-v8ob.onrender.com/api/v1/stem@home/student/classes",
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      
      if (response.data && response.data.status === 'SUCCESS' && Array.isArray(response.data.data)) {
        setClasses(response.data.data);
        setError('');
      } else {
        setError("No classes found");
        setClasses([]);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      setError("Failed to load your classes. Please try again.");
      setClasses([]);
    } finally {
      setLoading(false);
    }
  };

  const handleClassSelect = (selectedClass) => {
    navigate(`/start-submission/${selectedClass._id}`, {
      state: { className: selectedClass.name }
    });
  };

  if (loading) {
    return (
      <div className="center-form">
        <div className="loading-message">Loading classes...</div>
      </div>
    );
  }

  return (
    <div className="center-form">
      <h1>Start Submission</h1>
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="class-selection">
        <h2>Select Your Class</h2>
        <div className="class-grid">
          {classes.length > 0 ? (
            classes.map(cls => (
              <div
                key={cls._id}
                className="class-box"
                onClick={() => handleClassSelect(cls)}
              >
                <h3>{cls.name}</h3>
              </div>
            ))
          ) : (
            <div className="no-classes">
              No classes available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartSubmission;
import React, { useState } from "react";
import axios from "axios";
import "./setHighMarkAnswer.styles.scss";

const SetHighMarkAnswer = () => {
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAIAnswer = async () => {
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        "https://api.anthropic.com/v1/complete",
        {
          prompt: "\n\nHuman: Create a high-mark answer for the following question:\nWhat is artificial intelligence?\n\nAssistant:",
          model: "claude-2",
          max_tokens_to_sample: 300,
          temperature: 0.7,
        },
        {
          headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_CLAUDE_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      setAnswer(response.data.completion);
    } catch (err) {
      console.error("Error generating AI answer:", err);
      setError("Failed to generate answer using AI. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    if (!answer.trim()) {
      alert("Please enter or generate an answer.");
      return;
    }
    console.log("Saved Answer:", answer);
    alert("Answer saved successfully!");
  };

  return (
    <div className="set-high-mark-page">
      <h1>Set High Mark Answer</h1>
      <textarea
        placeholder="Type the ideal answer here"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <div className="buttons">
        <button onClick={handleAIAnswer} disabled={loading}>
          {loading ? "Generating..." : "Use AI"}
        </button>
        <button onClick={handleSave}>Save Answer</button>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default SetHighMarkAnswer;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';
import './SubmissionDetails.css';

const SubmissionDetails = () => {
  const { submissionId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);
  const [expandedQuestions, setExpandedQuestions] = useState({});
  const [isMarked, setIsMarked] = useState(false);

  const toggleQuestion = (index) => {
    setExpandedQuestions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const markSubmission = async (submissionData) => {
    if (submissionData.status === 'pending' && !isMarked) {
      try {
        const response = await axios.post(
          getApiUrl(`/api/student/submissions/mark/${submissionData.classId?._id}/${submissionId}`),
          {},
          { headers: getAuthHeaders() }
        );
        console.log('Marking response:', response.data);
        setIsMarked(true);
        // Update the details with the marked data
        setDetails(prevDetails => ({
          ...prevDetails,
          status: 'marked',
          totalScore: response.data.data.totalScore,
          submissions: response.data.data.results.map((result, index) => ({
            ...prevDetails.submissions[index],
            score: result.score,
            feedback: result.feedback
          }))
        }));
      } catch (err) {
        console.error('Error marking submission:', err);
        // Don't show error to user as this is a background process
      }
    }
  };

  useEffect(() => {
    const fetchSubmissionDetails = async () => {
      try {
        const response = await axios.get(
          getApiUrl(API_ENDPOINTS.SUBMISSION.GET(submissionId)),
          { headers: getAuthHeaders() }
        );
        const submissionData = response.data.data;
        setDetails(submissionData);
        // Attempt to mark the submission if it's pending
        await markSubmission(submissionData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching details:', err);
        setError('Failed to load submission details');
        setLoading(false);
      }
    };

    if (submissionId) {
      fetchSubmissionDetails();
    }
  }, [submissionId]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return <div className="submission-details-container">
      <div className="loading">Loading submission details...</div>
    </div>;
  }

  if (error) {
    return <div className="submission-details-container">
      <div className="error">{error}</div>
    </div>;
  }

  if (!details) {
    return <div className="submission-details-container">
      <div className="error">No submission details found</div>
    </div>;
  }

  return (
    <div className="submission-details-container">
      <div className="submission-container">
        <div className="submission-info-card">
          <div className="submission-meta">
            <div className="meta-item">
              <span className="meta-label">Submitted on:</span>
              <span className="meta-value">{formatDate(details.submittedAt)}</span>
            </div>
            <div className="meta-item">
              <span className="meta-label">Status:</span>
              <span className="meta-value status">{details.status}</span>
            </div>
            <div className="meta-item">
              <span className="meta-label">Total Score:</span>
              <span className="meta-value score">
                {details.status === 'pending' ? 'Pending' : details.totalScore}
              </span>
            </div>
          </div>
        </div>

        <div className="questions-list">
          {details.submissions?.map((submission, index) => (
            <div key={index} className="question-card">
              <div className="question-content">
                <div className="question-header">
                  <h3>Question {index + 1}</h3>
                </div>
                <div className="question-text">
                  {submission.questionId?.question}
                </div>
                <div className="answer-section">
                  <div className="answer-label">Your Answer:</div>
                  <div className="answer-text">
                    {submission.answer || 'No answer provided'}
                  </div>
                </div>
                <div className="score-section">
                  <div className="score-label">Score:</div>
                  <div className="score-value">
                    {details.status === 'pending' ? 'Pending' : submission.score}
                  </div>
                </div>
                {submission.feedback && (
                  <div className="feedback-section">
                    <div className="feedback-label">Feedback:</div>
                    <div className="feedback-text">{submission.feedback}</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <button 
          onClick={() => navigate('/my-submissions')} 
          className="back-button"
        >
          Back to Submissions
        </button>
      </div>
    </div>
  );
};

export default SubmissionDetails; 
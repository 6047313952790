import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Classes.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const StudentClasses = () => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData) {
          throw new Error('User data not found');
        }

        const response = await axios.get(
          getApiUrl(API_ENDPOINTS.STUDENT.CLASSES(userData.userId)),
          { headers: getAuthHeaders() }
        );

        if (response.data.success) {
          setClasses(response.data.classes || []);
        } else {
          setClasses([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching classes:", error);
        setError("Failed to load classes");
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  if (loading) {
    return (
      <div className="container">
        <div className="header">
          <h1>My Classes</h1>
        </div>
        <div className="loading">Loading classes...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <div className="header">
          <h1>My Classes</h1>
        </div>
        <div className="error-state">
          <div className="error-message">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="header">
        <h1>My Classes ({classes.length})</h1>
      </div>

      {classes.length === 0 ? (
        <div className="empty-state">
          <div className="empty-state-icon">📚</div>
          <div className="empty-state-text">You are not enrolled in any classes yet</div>
        </div>
      ) : (
        <div className="classes-grid">
          {classes.map((classItem) => (
            <div 
              key={classItem._id} 
              className="class-card"
              onClick={() => navigate(`/student/class/${classItem._id}`, {
                state: {
                  className: classItem.name,
                  teacherName: classItem.teacher?.name || 'Unknown Teacher'
                }
              })}
            >
              <h2 className="class-name">{classItem.name}</h2>
              <div className="class-info">
                <p>
                  <span className="info-label">Teacher:</span>
                  <span className="info-value">{classItem.teacher?.name || 'Unknown Teacher'}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StudentClasses; 
import React from 'react';
import './Payments.css';

const Payments = () => {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <h1>Payments</h1>
        <div className="coming-soon-badge">Coming Soon</div>
        <p className="subtitle">Our payment system is currently under development.</p>
      </div>
    </div>
  );
};

export default Payments; 
import { useState, useEffect } from "react";

function useUserRole() {
  const [role, setRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        const user = JSON.parse(userData);
        setRole(user.role?.toLowerCase());
      }
    };

    fetchUserRole();
  }, []);

  return { role };
}

export default useUserRole;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Form } from 'react-bootstrap';
import './AllClasses.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const AllClasses = () => {
  const [classes, setClasses] = useState([]);
  const [teachers, setTeachers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterTeacher, setFilterTeacher] = useState('all');
  const navigate = useNavigate();
  const classesPerPage = 9;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First fetch all classes
        const classesResponse = await axios.get(
          getApiUrl(API_ENDPOINTS.CLASS.ALL),
          { headers: getAuthHeaders() }
        );

        // Get unique teacher IDs from classes
        const teacherIds = [...new Set(classesResponse.data.classes.map(c => c.teacher))];

        // Fetch teachers data
        const teachersResponse = await axios.get(
          getApiUrl(API_ENDPOINTS.ADMIN.USERS),
          { headers: getAuthHeaders() }
        );

        // Create a map of teacher IDs to names
        const teacherMap = {};
        const users = teachersResponse.data.users || [];
        users.forEach(user => {
          if (user.role?.toLowerCase() === 'teacher') {
            teacherMap[user._id] = user.name;
          }
        });

        setTeachers(teacherMap);
        setClasses(classesResponse.data.classes || []);
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response?.status !== 404) {
          setError('Failed to load classes');
        } else {
          setClasses([]);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClassClick = (classId) => {
    navigate(`/all-classes/${classId}`);
  };

  const getTeacherName = (teacherId) => {
    if (!teacherId) return 'No Teacher Assigned';
    return teachers[teacherId] || 'Unknown Teacher';
  };

  // Filter classes based on search term and teacher filter
  const filteredClasses = classes.filter(classItem => {
    const matchesSearch = (classItem.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                         (classItem.teacher?.name || '').toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTeacher = filterTeacher === 'all' || classItem.teacher?.id === filterTeacher;
    return matchesSearch && matchesTeacher;
  });

  // Get current classes for pagination
  const indexOfLastClass = currentPage * classesPerPage;
  const indexOfFirstClass = indexOfLastClass - classesPerPage;
  const currentClasses = filteredClasses.slice(indexOfFirstClass, indexOfLastClass);
  const totalPages = Math.ceil(filteredClasses.length / classesPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <div className="all-classes-container">
        <div className="loading">Loading classes...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="all-classes-container">
        <div className="error">{error}</div>
      </div>
    );
  }

  return (
    <Container fluid className="all-classes-container">
      {/* Page Header */}
      <div className="page-header">
        <h1>All Classes for {JSON.parse(localStorage.getItem('user'))?.school || 'School'}</h1>
        <p className="total-count">Total Classes: {classes.length}</p>
      </div>

      {/* Search and Filter Controls */}
      <div className="controls-container">
        <Form.Control
          type="text"
          placeholder="Search by class name or teacher..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <Form.Select
          value={filterTeacher}
          onChange={(e) => setFilterTeacher(e.target.value)}
          className="filter-select"
        >
          <option value="all">All Teachers</option>
          {Object.entries(teachers).map(([id, name]) => (
            <option key={id} value={id}>{name}</option>
          ))}
        </Form.Select>
      </div>

      {currentClasses.length === 0 ? (
        <div className="empty-state">
          <p>No classes found matching your criteria.</p>
        </div>
      ) : (
        <>
          <div className="classes-grid">
            {currentClasses.slice(0, 9).map((classItem) => (
              <div 
                key={classItem._id} 
                className="class-card"
                onClick={() => handleClassClick(classItem._id)}
                role="button"
                tabIndex={0}
              >
                <div className="class-card-content">
                  <h3 style={{ color: '#000', fontWeight: '700' }}>{classItem.name}</h3>
                  <p>Teacher: {classItem.teacher?.name}</p>
                  <p>Students: {classItem.students?.length || 0}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="pagination-container">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                Next
              </button>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default AllClasses; 
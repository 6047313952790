import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './ClassDetails.css';

const ClassDetails = () => {
  const [classData, setClassData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { classId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        // Fetch class details
        const classResponse = await axios.get(
          `https://backend-master-v8ob.onrender.com/api/v1/stem@home/class/${classId}`,
          { headers }
        );
        setClassData(classResponse.data.class);

        // Fetch questions
        const questionsResponse = await axios.get(
          `https://backend-master-v8ob.onrender.com/api/v1/stem@home/class/${classId}/questions`,
          { headers }
        );
        setQuestions(questionsResponse.data.questions || []);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch class details. Please try again later.');
        setLoading(false);
      }
    };

    if (classId) {
      fetchData();
    }
  }, [classId]);

  if (loading) {
    return <div className="loading">Loading class details...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!classData) {
    return <div className="error">Class not found</div>;
  }

  return (
    <div className="class-details-container">
      <h1 className="class-name">{classData.name}</h1>
      
      <div className="teacher-info">
        <h2>Teacher:</h2>
        <p>{classData.teacher?.name || 'Not assigned'}</p>
      </div>

      <div className="questions-section">
        <h2>Questions</h2>
        {questions.length > 0 ? (
          <ul className="questions-list">
            {questions.map((question) => (
              <li key={question._id} className="question-item">
                <h3>{question.question}</h3>
                <div className="question-details">
                  <span>Marks: {question.marks}</span>
                  <span>Mark Range: {question.lowMarkRange} - {question.highMarkRange}</span>
                </div>
                {question.rubric && (
                  <div className="rubric">
                    <h4>Rubric:</h4>
                    <p>{question.rubric}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No questions have been added to this class yet.</p>
        )}
      </div>

      <div className="students-section">
        <h2>Students</h2>
        {classData.students?.length > 0 ? (
          <ul className="students-list">
            {classData.students.map((student) => (
              <li key={student._id} className="student-item">
                {student.name}
              </li>
            ))}
          </ul>
        ) : (
          <p>No students enrolled in this class yet.</p>
        )}
      </div>
    </div>
  );
};

export default ClassDetails; 
import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import TeacherPage from "./pages/TeacherPage/teacher.page";
import Camera from "./components/ui/Camera/camera.component";
import RenderRoute from "./hooks/renderRouteMiddleware";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Signup from "./pages/signup/Signup";
import StartSubmission from "./pages/startSubmission/StartSubmission";
import QuestionView from "./pages/startSubmission/QuestionView";
import Submissions from "./pages/submissions/Submissions";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import PrivateRoute from "./components/PrivateRoute";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import useUserRole from "./hooks/useUserRole";
import ClassDetails from './pages/TeacherPage/ClassDetails';
import TeacherClasses from './pages/TeacherPage/Classes';
import InsertNewQuestion from './pages/TeacherPage/insertNewQuestion.page';
import SetHighMarkAnswer from './pages/TeacherPage/setHighMarkAnswer.page';
import RubricList from './pages/TeacherPage/rubricList.page';
import EditQuestion from './pages/TeacherPage/EditQuestion';
import ViewQuestions from './pages/TeacherPage/ViewQuestions';
import SubmissionComplete from './pages/StudentPage/SubmissionComplete';
import SubmissionDetails from './pages/StudentPage/SubmissionDetails';
import Sidebar from './components/Sidebar/Sidebar';
import Payments from './pages/payments/Payments';
import Users from './pages/users/Users';
import AdminCreateClass from './pages/admin/CreateClass';
import CreateClass from './pages/TeacherPage/CreateClass';
import AllClasses from './pages/admin/AllClasses';
import ClassDetailsAdmin from './pages/admin/ClassDetails';
import AllSubmissions from './pages/admin/AllSubmissions';
import AllClassesView from './pages/TeacherPage/AllClasses';
import StudentClasses from './pages/StudentPage/Classes';
import StudentClassDetails from './pages/StudentPage/ClassDetails';
import MyClasses from './pages/StudentPage/MyClasses';
import MySubmissions from './pages/StudentPage/MySubmissions';

// Layout component for authenticated pages
const AuthenticatedLayout = ({ children }) => {
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");

  return (
    <div className="layout">
      <Sidebar userName={userName} role={role} />
      <div className="main-content">
        {children}
      </div>
      <style jsx>{`
        .layout {
          display: flex;
          min-height: 100vh;
        }
        
        .main-content {
          flex: 1;
          margin-left: 250px;
          padding: 2rem;
          background-color: #f8f9fa;
          min-height: 100vh;
        }

        @media (max-width: 768px) {
          .main-content {
            margin-left: 0;
            padding: 1rem;
            padding-top: calc(60px + 1rem);
          }
        }
      `}</style>
    </div>
  );
};

function App() {
  const { role } = useUserRole();

  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />

        {/* Protected routes */}
        <Route element={<RenderRoute />}>
          <Route element={<PrivateRoute />}>
            {/* Root path redirect */}
            <Route path="/" element={
              <Navigate to={role === 'admin' ? "/admin-dashboard" : "/dashboard"} replace />
            } />

            {/* Admin routes */}
            <Route path="/admin-dashboard" element={
              <ProtectedRoute role="admin">
                <AuthenticatedLayout>
                  <AdminDashboard />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/payments" element={
              <ProtectedRoute role="admin">
                <AuthenticatedLayout>
                  <Payments />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            {/* Teacher routes */}
            <Route path="/classes" element={
              <ProtectedRoute roles={['teacher', 'student']}>
                <AuthenticatedLayout>
                  {role === 'teacher' && <TeacherClasses />}
                  {role === 'student' && <StudentClasses />}
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/create-class" element={
              <ProtectedRoute roles={['admin', 'teacher']}>
                <AuthenticatedLayout>
                  {role === 'admin' ? <AdminCreateClass /> : <CreateClass />}
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/class/:classId" element={
              <ProtectedRoute role="teacher">
                <AuthenticatedLayout>
                  <ClassDetails />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/insert-question" element={
              <ProtectedRoute role="teacher">
                <AuthenticatedLayout>
                  <InsertNewQuestion />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/set-high-mark-answer" element={
              <ProtectedRoute role="teacher">
                <AuthenticatedLayout>
                  <SetHighMarkAnswer />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/rubrics" element={
              <ProtectedRoute role="teacher">
                <AuthenticatedLayout>
                  <RubricList />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/edit-question/:id" element={
              <ProtectedRoute role="teacher">
                <AuthenticatedLayout>
                  <EditQuestion />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/teacher" element={
              <ProtectedRoute role="teacher">
                <AuthenticatedLayout>
                  <TeacherPage />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/view-questions" element={
              <ProtectedRoute role="teacher">
                <AuthenticatedLayout>
                  <ViewQuestions />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            {/* General protected routes */}
            <Route path="/dashboard" element={
              <ProtectedRoute roles={['teacher', 'student']}>
                <AuthenticatedLayout>
                  <Dashboard />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/start-submission" element={
              <ProtectedRoute>
                <AuthenticatedLayout>
                  <StartSubmission />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/start-submission/:classId" element={
              <ProtectedRoute>
                <AuthenticatedLayout>
                  <QuestionView />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/takeImage" element={
              <ProtectedRoute>
                <AuthenticatedLayout>
                  <Camera />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/submissions" element={
              <ProtectedRoute roles={['teacher', 'student']}>
                <AuthenticatedLayout>
                  <Submissions />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/submission-complete" element={
              <ProtectedRoute>
                <AuthenticatedLayout>
                  <SubmissionComplete />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/users" element={
              <ProtectedRoute role="admin">
                <AuthenticatedLayout>
                  <Users />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/all-classes" element={
              <ProtectedRoute roles={['admin']}>
                <AuthenticatedLayout>
                  <AllClasses />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/all-classes/:classId" element={
              <ProtectedRoute roles={['admin']}>
                <AuthenticatedLayout>
                  <ClassDetailsAdmin />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/all-submissions" element={
              <ProtectedRoute roles={['admin']}>
                <AuthenticatedLayout>
                  <AllSubmissions />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/student/class/:id" element={
              <ProtectedRoute role="student">
                <AuthenticatedLayout>
                  <StudentClassDetails />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/all-classes/:id" element={<AllClassesView />} />

            {/* Class details route for both teachers and students */}
            <Route path="/class/:id" element={
              <ProtectedRoute roles={['teacher', 'student']}>
                <AuthenticatedLayout>
                  {role === 'teacher' ? <ClassDetails /> : <StudentClassDetails />}
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            {/* Student routes */}
            <Route path="/my-classes" element={
              <ProtectedRoute role="student">
                <AuthenticatedLayout>
                  <MyClasses />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/my-submissions" element={
              <ProtectedRoute role="student">
                <AuthenticatedLayout>
                  <MySubmissions />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />

            <Route path="/my-submissions/:submissionId" element={
              <ProtectedRoute role="student">
                <AuthenticatedLayout>
                  <SubmissionDetails />
                </AuthenticatedLayout>
              </ProtectedRoute>
            } />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;

import React, { useState, useRef } from 'react';
import './camera.styles.scss';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Camera = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { questionId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const fileInputRef = useRef();

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        alert('Image size should be less than 5MB');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (!selectedImage) {
      alert('Please select an image first');
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('questionId', questionId);

    try {
      const response = await fetch('/api/upload-image', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Image uploaded successfully');
        navigate(-1);
      } else {
        alert('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error uploading image');
    }
  };

  return (
    <div className="camera-container">
      <div className="upload-section">
        <div className="upload-prompt">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageSelect}
            ref={fileInputRef}
            className="file-input"
          />
          <p className="upload-hint">Supported formats: PNG, JPG, JPEG, GIF (max 5MB)</p>
        </div>
      </div>

      {previewUrl && (
        <div className="preview-section">
          <img src={previewUrl} alt="Preview" className="image-preview" />
        </div>
      )}

      <div className="button-section">
        <button className="cancel-btn" onClick={() => navigate(-1)}>Cancel</button>
        <button className="submit-btn" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default Camera;
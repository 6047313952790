import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, role, roles }) => {
  const userData = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  
  let userRole = '';
  if (userData) {
    try {
      const user = JSON.parse(userData);
      userRole = user.role;
    } catch (error) {
      console.error('Error parsing user data:', error);
    }
  }

  if (!token) {
    return <Navigate to="/login" state={{ error: "Please log in to continue." }} />;
  }

  // Check if roles array is provided
  if (roles) {
    if (!roles.map(r => r.toLowerCase()).includes(userRole?.toLowerCase())) {
      // Redirect based on user role
      switch (userRole?.toLowerCase()) {
        case 'admin':
          return <Navigate to="/admin-dashboard" />;
        case 'teacher':
          return <Navigate to="/dashboard" />;
        default:
          return <Navigate to="/dashboard" />;
      }
    }
  }
  // Check single role if provided
  else if (role && userRole?.toLowerCase() !== role.toLowerCase()) {
    // Redirect based on user role
    switch (userRole?.toLowerCase()) {
      case 'admin':
        return <Navigate to="/admin-dashboard" />;
      case 'teacher':
        return <Navigate to="/dashboard" />;
      default:
        return <Navigate to="/dashboard" />;
    }
  }

  return children;
};

export default ProtectedRoute;

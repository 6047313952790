import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './MyClasses.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const MyClasses = () => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        console.log('Fetching classes...');
        const user = localStorage.getItem('user');
        const userData = JSON.parse(user);

        if (!userData || !userData.userId) {
          throw new Error('User data not found');
        }

        const response = await axios.get(
          getApiUrl(API_ENDPOINTS.STUDENT.CLASSES(userData.userId)),
          { headers: getAuthHeaders() }
        );

        console.log('Classes response:', response.data);

        if (response.data.success) {
          const classesData = response.data.classes || [];
          setClasses(classesData);
        } else {
          setClasses([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setError(error.response?.data?.message || "Failed to load classes");
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  const handleClassClick = (classItem) => {
    navigate(`/student/class/${classItem._id}`, {
      state: {
        className: classItem.name,
        teacherName: classItem.teacher?.name || 'Not assigned'
      }
    });
  };

  if (loading) {
    return <div className="my-classes-container">
      <div className="loading">Loading classes...</div>
    </div>;
  }

  if (error) {
    return <div className="my-classes-container">
      <div className="error">{error}</div>
    </div>;
  }

  return (
    <div className="my-classes-container">
      <div className="classes-header">
        <h1>My Classes</h1>
      </div>

      <div className="classes-content">
        {(!classes || classes.length === 0) ? (
          <div className="empty-state">
            <p>You are not enrolled in any classes yet.</p>
          </div>
        ) : (
          <div className="classes-grid">
            {classes.map((classItem) => (
              <div 
                key={classItem._id} 
                className="class-card" 
                onClick={() => handleClassClick(classItem)}
              >
                <h3>{classItem.name}</h3>
                <p>Teacher: {classItem.teacher?.name || 'Not assigned'}</p>
                <p>Questions: {classItem.questions?.length || 0}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyClasses; 
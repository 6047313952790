import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Sidebar.css';
import snapgradeLogo from '../../img/snapgrade-logo.png';
// Import school logos
import brentwoodLogo from '../../img/Brentwood-School.png';
import ascendLogo from '../../img/Ascend-Learning-Trust.png';
import caldayLogo from '../../img/Calday-Grammar-School.png';
import meridianLogo from '../../img/Meridian-Trust.png';
import defaultSchoolLogo from '../../img/default-school.png';
import HelpPopup from '../ui/HelpPopup/HelpPopup';
import SettingsPopup from '../ui/SettingsPopup/SettingsPopup';
import { 
  FiGrid as DashboardIcon,
  FiBook as ClassesIcon,
  FiFileText as SubmissionsIcon,
  FiHelpCircle as HelpIcon,
  FiSettings as SettingsIcon,
  FiUsers as UsersIcon,
  FiDollarSign as PaymentsIcon,
  FiLogOut as LogoutIcon,
  FiList as QuestionsIcon
} from 'react-icons/fi';

// School name to logo mapping
const schoolLogos = {
  'Brentwood School': brentwoodLogo,
  'Ascend Learning Trust': ascendLogo,
  'Calday Grammar School': caldayLogo,
  'Meridian Trust': meridianLogo
};

const DEFAULT_SCHOOL_LOGO = defaultSchoolLogo; // Use our custom default logo

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  
  // Add this to ensure consistent role handling
  const userRole = user?.role?.toLowerCase() || '';
  console.log('User data in Sidebar:', user);
  console.log('User role in Sidebar:', userRole);

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const getSubmissionsPath = () => {
    switch (userRole) {
      case 'teacher':
        return '/submissions';
      case 'student':
        return '/my-submissions';
      case 'admin':
        return '/all-submissions';
      default:
        return '/submissions';
    }
  };

  const getClassesPath = () => {
    switch (userRole) {
      case 'teacher':
        return '/classes';
      case 'student':
        return '/my-classes';
      case 'admin':
        return '/all-classes';
      default:
        return '/classes';
    }
  };

  const getDashboardPath = () => {
    return userRole === 'admin' ? '/admin-dashboard' : '/dashboard';
  };

  const getSchoolLogo = (schoolName) => {
    return schoolLogos[schoolName] || DEFAULT_SCHOOL_LOGO;
  };

  return (
    <div className="sidebar" data-role={userRole}>
      <div className="sidebar-header">
        <div className="logo-container">
          <img src={snapgradeLogo} alt="SnapGrade Logo" className="logo" />
          {user?.school && (
            <img 
              src={getSchoolLogo(user.school)} 
              alt={`${user.school || 'School'} Logo`} 
              className="school-logo" 
            />
          )}
        </div>
        <div className="user-badge" style={{ backgroundColor: getRoleBadgeColor(userRole) }}>
          {userRole}
        </div>
        <div className="welcome-text">Welcome {user?.name}</div>
      </div>

      <nav className="sidebar-nav">
        <Link to={getDashboardPath()} className={`nav-item ${isActive(getDashboardPath()) ? 'active' : ''}`}>
          <DashboardIcon className="nav-icon" />
          <span>Dashboard</span>
        </Link>

        {userRole === 'admin' && (
          <Link to="/users" className={`nav-item ${isActive('/users') ? 'active' : ''}`}>
            <UsersIcon className="nav-icon" />
            <span>Users</span>
          </Link>
        )}

        <Link to={getClassesPath()} className={`nav-item ${isActive(getClassesPath()) ? 'active' : ''}`}>
          <ClassesIcon className="nav-icon" />
          <span>Classes</span>
        </Link>

        {userRole === 'teacher' && (
          <Link to="/view-questions" className={`nav-item ${isActive('/view-questions') ? 'active' : ''}`}>
            <QuestionsIcon className="nav-icon" />
            <span>Questions</span>
          </Link>
        )}

        <Link to={getSubmissionsPath()} className={`nav-item ${isActive(getSubmissionsPath()) ? 'active' : ''}`}>
          <SubmissionsIcon className="nav-icon" />
          <span>Submissions</span>
        </Link>

        <button 
          onClick={() => {
            console.log('Opening help popup with role:', userRole);
            setShowHelpPopup(true);
          }} 
          className="nav-item"
        >
          <HelpIcon className="nav-icon" />
          <span>Help</span>
        </button>

        <button onClick={() => setShowSettingsPopup(true)} className="nav-item">
          <SettingsIcon className="nav-icon" />
          <span>Settings</span>
        </button>

        <button onClick={handleLogout} className="nav-item">
          <LogoutIcon className="nav-icon" />
          <span>Log Out</span>
        </button>
      </nav>

      {showHelpPopup && (
        <HelpPopup 
          isOpen={showHelpPopup}
          onClose={() => setShowHelpPopup(false)}
          userRole={userRole}
        />
      )}

      <SettingsPopup
        show={showSettingsPopup}
        onHide={() => setShowSettingsPopup(false)}
        userName={user?.name}
        userRole={userRole}
        schoolName={user?.school}
      />
    </div>
  );
};

const getRoleBadgeColor = (role) => {
  switch (role) {
    case 'student':
      return '#4ade80'; // light green
    case 'teacher':
      return '#60a5fa'; // light blue
    case 'admin':
      return '#f87171'; // light red
    default:
      return '#9ca3af'; // gray
  }
};

export default Sidebar; 
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SubmissionComplete.css';

const SubmissionComplete = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const submissionData = location.state || {};
  const { scores = {}, totalScore = 0, answers = {}, questions = [] } = submissionData;

  // If no data was passed, show error and redirect option
  if (!location.state || Object.keys(scores).length === 0) {
    return (
      <div className="submission-complete">
        <h1>No Submission Data</h1>
        <p>There was an error loading your submission results.</p>
        <button 
          onClick={() => navigate('/dashboard')} 
          className="back-button"
        >
          Back to Dashboard
        </button>
      </div>
    );
  }

  const maxPossibleScore = questions.reduce((total, q) => total + (q.marks || 0), 0);

  return (
    <div className="submission-complete">
      <h1>Submission Complete</h1>
      
      <div className="total-score-card">
        <h2>Overall Score</h2>
        <div className="score">
          {totalScore} / {maxPossibleScore}
        </div>
        <div className="percentage">
          {maxPossibleScore > 0 
            ? `${((totalScore / maxPossibleScore) * 100).toFixed(1)}%` 
            : '0%'}
        </div>
      </div>

      <div className="questions-breakdown">
        <h2>Detailed Breakdown</h2>
        {questions.map((question, index) => {
          const questionScore = scores[question._id] || { score: 0, feedback: 'No feedback available' };
          const studentAnswer = answers[question._id] || 'No answer provided';
          
          return (
            <div key={question._id || index} className="question-result">
              <h3>Question {index + 1}</h3>
              <div className="question-text">{question.question}</div>
              
              <div className="answer-section">
                <h4>Your Answer:</h4>
                <div className="answer-text">{studentAnswer}</div>
              </div>

              <div className="score-section">
                <div className="question-score">
                  Score: {questionScore.score} / {question.marks || 0}
                </div>
                <div className="feedback">
                  <h4>Feedback:</h4>
                  <p>{questionScore.feedback}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <button 
        onClick={() => navigate('/dashboard')} 
        className="back-button"
      >
        Back to Dashboard
      </button>
    </div>
  );
};

export default SubmissionComplete; 
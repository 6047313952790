import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateClass.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const CreateClass = () => {
  const [className, setClassName] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [currentStep, setCurrentStep] = useState(1); // 1: Class Info, 2: Teacher Selection, 3: Student Selection
  const navigate = useNavigate();

  // Function to fetch teachers
  const fetchTeachers = async () => {
    try {
      const response = await axios.get(
        getApiUrl(API_ENDPOINTS.TEACHER.ALL),
        { headers: getAuthHeaders() }
      );
      console.log('Raw teachers data:', response.data);
      const teachersData = response.data.teachers || [];
      console.log('Individual teachers:', teachersData.map(t => ({ id: t.id, _id: t._id, name: t.name })));
      setTeachers(teachersData);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  // Function to fetch students
  const fetchStudents = async () => {
    try {
      const response = await axios.get(
        getApiUrl(API_ENDPOINTS.STUDENT.ALL),
        { headers: getAuthHeaders() }
      );
      setStudents(response.data.students || []);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  useEffect(() => {
    fetchTeachers();
    fetchStudents();
  }, []);

  // Handle the selection of a teacher
  const handleTeacherSelection = (teacher) => {
    console.log('Raw teacher selection:', teacher);
    console.log('Teacher IDs available:', { id: teacher.id, _id: teacher._id });
    setSelectedTeacher(teacher);
  };

  // Handle student selection
  const handleStudentSelection = (student) => {
    setSelectedStudentIds(prev => {
      const isSelected = prev.includes(student.id);
      if (isSelected) {
        return prev.filter(id => id !== student.id);
      } else {
        return [...prev, student.id];
      }
    });
  };

  // Handle next step
  const handleNextStep = () => {
    if (currentStep === 1 && className) {
      setCurrentStep(2);
    } else if (currentStep === 2 && selectedTeacher) {
      setCurrentStep(3);
    }
  };

  // Handle back step
  const handleBackStep = () => {
    setCurrentStep(prev => Math.max(1, prev - 1));
  };

  // Handle class creation
  const handleCreateClass = async () => {
    if (!className || !selectedTeacher || selectedStudentIds.length === 0) return;

    try {
      console.log('Full selected teacher object:', selectedTeacher);
      
      // Get the teacher ID from the selected teacher
      const teacherId = selectedTeacher.id;
      console.log('Using teacher ID:', teacherId);
      
      const payload = {
        name: className,
        teacherId: teacherId, // Changed to teacherId as per API docs for admin class creation
        students: selectedStudentIds
      };
      
      console.log('Final payload being sent:', payload);

      const response = await axios.post(
        getApiUrl(API_ENDPOINTS.CLASS.CREATE),
        payload,
        { 
          headers: {
            ...getAuthHeaders(),
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Full API response:', response);
      console.log('Response data:', response.data);

      navigate('/all-classes');
    } catch (error) {
      console.error("Error creating class:", error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  };

  return (
    <div className="create-class-container">
      <div className="create-class-header">
        <h1>Create New Class</h1>
      </div>
      <div className="create-class-content">
        {/* Step indicators */}
        <div className="steps-indicator">
          <div className={`step ${currentStep >= 1 ? 'active' : ''} ${currentStep > 1 ? 'completed' : ''}`}>1. Class Info</div>
          <div className={`step ${currentStep >= 2 ? 'active' : ''} ${currentStep > 2 ? 'completed' : ''}`}>2. Select Teacher</div>
          <div className={`step ${currentStep >= 3 ? 'active' : ''}`}>3. Add Students</div>
        </div>

        {/* Step 1: Class Info */}
        {currentStep === 1 && (
          <div className="step-content">
            <div className="input-container">
              <input
                type="text"
                placeholder="Maths (Tuesday 10am)"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                className="form-control"
              />
              <button 
                className="btn btn-primary next-btn"
                onClick={handleNextStep}
                disabled={!className}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 2: Teacher Selection */}
        {currentStep === 2 && (
          <div className="step-content">
            <h2>Select a Teacher</h2>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Select</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {teachers.map((teacher) => {
                    const teacherId = teacher._id || teacher.id;
                    const selectedId = selectedTeacher?._id || selectedTeacher?.id;
                    
                    return (
                      <tr 
                        key={teacherId}
                        className={selectedId === teacherId ? 'table-active' : ''}
                        onClick={() => handleTeacherSelection(teacher)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="teacherSelection"
                              checked={selectedId === teacherId}
                              onChange={() => handleTeacherSelection(teacher)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </div>
                        </td>
                        <td>{teacher.name}</td>
                        <td>{teacher.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="step-actions">
              <button className="btn btn-secondary back-btn" onClick={handleBackStep}>
                Back
              </button>
              <button 
                className="btn btn-primary next-btn"
                onClick={handleNextStep}
                disabled={!selectedTeacher}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 3: Student Selection */}
        {currentStep === 3 && (
          <div className="step-content">
            <h2>Add Students to Class</h2>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Select</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student) => (
                    <tr 
                      key={student.id}
                      className={selectedStudentIds.includes(student.id) ? 'table-active' : ''}
                      onClick={() => handleStudentSelection(student)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={selectedStudentIds.includes(student.id)}
                            onChange={() => handleStudentSelection(student)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      </td>
                      <td>{student.name || ''}</td>
                      <td>{student.email || ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="step-actions">
              <button className="btn btn-secondary back-btn" onClick={handleBackStep}>
                Back
              </button>
              <button 
                className="btn btn-primary create-btn"
                onClick={handleCreateClass}
                disabled={selectedStudentIds.length === 0}
              >
                Create Class
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateClass; 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AllSubmissions.css';

const AllSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submissionCount, setSubmissionCount] = useState(0);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          'https://backend-master-v8ob.onrender.com/api/v1/stem@home/admin/submissions',
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data.success) {
          setSubmissions(response.data.submissions || []);
          setSubmissionCount(response.data.count || 0);
        } else {
          setError('Failed to fetch submissions data');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching submissions:', err);
        setError('Failed to fetch submissions. Please try again later.');
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return <div className="loading">Loading submissions...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="all-submissions-container">
      <div className="submissions-header">
        <h1>All Submissions</h1>
        <p className="total-count">Total Submissions: {submissionCount}</p>
      </div>

      {submissions.length === 0 ? (
        <div className="empty-state">
          <p>No submissions found.</p>
        </div>
      ) : (
        <div className="table-container">
          <table className="submissions-table">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Student Email</th>
                <th>Class</th>
                <th>Total Score</th>
                <th>Questions Answered</th>
                <th>Submission Date</th>
              </tr>
            </thead>
            <tbody>
              {submissions.map((submission) => (
                <tr key={submission.id}>
                  <td>{submission.student.name}</td>
                  <td>{submission.student.email}</td>
                  <td>{submission.class.name}</td>
                  <td>{submission.totalScore}</td>
                  <td>{submission.submissions.length}</td>
                  <td>{formatDate(submission.submittedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AllSubmissions; 
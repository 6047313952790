import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AllClasses.css';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';

const AllClasses = () => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(
          getApiUrl(API_ENDPOINTS.TEACHER.CLASSES),
          { headers: getAuthHeaders() }
        );
        setClasses(response.data.data || []);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching classes:", err);
        setError("Failed to load classes");
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  if (loading) {
    return (
      <div className="container">
        <div className="header">
          <h1>Classes</h1>
          <button 
            className="add-class-button"
            onClick={() => navigate('/create-class')}
          >
            + Add New Class
          </button>
        </div>
        <div className="loading">Loading classes...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <div className="header">
          <h1>Classes</h1>
          <button 
            className="add-class-button"
            onClick={() => navigate('/create-class')}
          >
            + Add New Class
          </button>
        </div>
        <div className="error-state">
          <div className="error-message">{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="header">
        <h1>Classes ({classes.length})</h1>
        <button 
          className="add-class-button"
          onClick={() => navigate('/create-class')}
        >
          + Add New Class
        </button>
      </div>

      {classes.length === 0 ? (
        <div className="empty-state">
          <div className="empty-state-icon">📚</div>
          <div className="empty-state-text">You haven't created any classes yet</div>
          <button 
            className="add-class-button"
            onClick={() => navigate('/create-class')}
          >
            Create Your First Class
          </button>
        </div>
      ) : (
        <div className="classes-grid">
          {classes.map((classItem) => (
            <div 
              key={classItem._id} 
              className="class-card"
              onClick={() => navigate(`/class/${classItem._id}`)}
            >
              <h2 className="class-name">{classItem.name}</h2>
              <div className="class-stats">
                <div className="stat-item">
                  <div className="stat-label">Students</div>
                  <div className="stat-value">{classItem.students?.length || 0}</div>
                </div>
                <div className="stat-item">
                  <div className="stat-label">Questions</div>
                  <div className="stat-value">{classItem.questions?.length || 0}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AllClasses; 
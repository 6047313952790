import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { getApiUrl, API_ENDPOINTS, getAuthHeaders } from '../../config/api.config';
import './ViewQuestions.css';

const ITEMS_PER_PAGE = 10;

const ViewQuestions = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sortBy, setSortBy] = useState('dateAdded');
  const [currentPage, setCurrentPage] = useState(1);
  const [classes, setClasses] = useState([]);
  const [showClassDropdown, setShowClassDropdown] = useState({});
  const [addingToClass, setAddingToClass] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (!userData) {
          throw new Error('No user data found');
        }
        const user = JSON.parse(userData);
        const teacherId = user.userId;
        const response = await axios.get(
          `https://backend-master-v8ob.onrender.com/api/v1/stem@home/question/questions/teacher/${teacherId}`,
          { headers: getAuthHeaders() }
        );
        setQuestions(response.data.data || []);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching questions:", err);
        setError("Failed to load questions");
        setQuestions([]);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(
        getApiUrl(API_ENDPOINTS.TEACHER.CLASSES),
        { headers: getAuthHeaders() }
      );
      if (response.data && response.data.data) {
        setClasses(response.data.data);
      } else {
        setClasses([]);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      setClasses([]);
    }
  };

  const handleAddToClass = async (questionId, classId) => {
    setAddingToClass(true);
    try {
      await axios.post(
        getApiUrl(API_ENDPOINTS.QUESTION.ADD_TO_CLASS),
        {
          questionId: questionId,
          classId: classId
        },
        { headers: getAuthHeaders() }
      );
      alert("Question added to class successfully!");
      setShowClassDropdown({});
    } catch (error) {
      console.error("Error adding question to class:", error);
      alert("Failed to add question to class. Please try again.");
    } finally {
      setAddingToClass(false);
    }
  };

  const handleDelete = async (questionId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this question?');
    
    if (isConfirmed) {
      try {
        const response = await axios.delete(
          getApiUrl(API_ENDPOINTS.QUESTION.DELETE(questionId)),
          { headers: getAuthHeaders() }
        );
        
        if (response.data && response.data.status === 'success') {
          setQuestions(questions.filter(q => q._id !== questionId));
          alert('Question deleted successfully!');
        } else {
          alert('Failed to delete question');
        }
      } catch (error) {
        console.error("Error deleting question:", error);
        alert('Failed to delete question. Please try again.');
      }
    }
  };

  const handleEdit = (questionId) => {
    console.log("Editing question with ID:", questionId);
    navigate(`/edit-question/${questionId}`);
  };

  const toggleDropdown = (questionId) => {
    setShowClassDropdown(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };

  const isMobile = windowWidth <= 768;
  const isTablet = windowWidth <= 1024 && windowWidth > 768;

  const sortOptions = [
    { label: 'Date Added (Newest First)', value: 'createdAt-desc' },
    { label: 'Date Added (Oldest First)', value: 'createdAt-asc' },
    { label: 'Marks (High to Low)', value: 'marks-desc' },
    { label: 'Marks (Low to High)', value: 'marks-asc' }
  ];

  const handleSort = (value) => {
    setSortBy(value);
  };

  const getSortedQuestions = () => {
    const sortedQuestions = [...questions];
    const [field, direction] = sortBy.split('-');

    return sortedQuestions.sort((a, b) => {
      if (field === 'createdAt') {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
      
      if (field === 'marks') {
        const marksA = parseInt(a.marks) || 0;
        const marksB = parseInt(b.marks) || 0;
        return direction === 'asc' ? marksA - marksB : marksB - marksA;
      }

      return 0;
    });
  };

  const getPaginatedQuestions = () => {
    const sortedQuestions = getSortedQuestions();
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return sortedQuestions.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const totalPages = Math.ceil(questions.length / ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const dropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: '0',
    backgroundColor: 'white',
    border: '1px solid #dee2e6',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 9999,
    minWidth: '200px',
    marginTop: '4px',
    maxHeight: '300px',
    overflowY: 'auto'
  };

  const dropdownButtonStyle = {
    width: '100%',
    padding: '8px 12px',
    textAlign: 'left',
    border: 'none',
    borderBottom: '1px solid #dee2e6',
    backgroundColor: 'white',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'block',
    '&:hover': {
      backgroundColor: '#f8f9fa'
    },
    '&:last-child': {
      borderBottom: 'none'
    }
  };

  const buttonStyle = {
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontWeight: "500",
    fontSize: "0.9rem",
    transition: "all 0.2s ease"
  };

  const actionButtonsContainerStyle = {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
  };

  const actionButtonBaseStyle = {
    padding: '8px 16px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: 'white',
    fontSize: '14px',
    minWidth: '90px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  };

  const addToButtonStyle = {
    ...actionButtonBaseStyle,
    backgroundColor: '#28a745',
  };

  const editButtonStyle = {
    ...actionButtonBaseStyle,
    backgroundColor: '#ffc107',
    color: '#000'
  };

  const deleteButtonStyle = {
    ...actionButtonBaseStyle,
    backgroundColor: '#dc3545',
  };

  const emptyStateStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 20px",
    textAlign: "center",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    margin: "20px 0"
  };

  const emptyStateIconStyle = {
    fontSize: "48px",
    color: "#6c757d",
    marginBottom: "16px"
  };

  const emptyStateTextStyle = {
    color: "#6c757d",
    marginBottom: "24px",
    fontSize: "1.1rem"
  };

  const createButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#007bff",
    color: "white",
    padding: "12px 24px",
    fontSize: "1rem",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "#0056b3"
    }
  };

  const errorStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    margin: "20px",
    backgroundColor: "#fff3f3",
    color: "#dc3545",
    borderRadius: "8px",
    border: "1px solid #ffcdd2",
    fontSize: "1rem",
    textAlign: "center"
  };

  const loadingContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    padding: "40px",
    color: "#6c757d",
  };

  if (loading) {
    return <div className="loading">Loading questions...</div>;
  }

  if (error) {
    return (
      <div className="container">
        <div className="header">
          <h1>Questions</h1>
        </div>
        <div className="empty-state">
          <div className="empty-state-icon">❌</div>
          <div className="empty-state-text">{error}</div>
          <button 
            className="add-question-button"
            onClick={() => navigate('/insert-question')}
          >
            Add New Question
          </button>
        </div>
      </div>
    );
  }

  if (questions.length === 0) {
    return (
      <div className="container">
        <div className="header">
          <h1>Questions</h1>
        </div>
        <div className="empty-state">
          <div className="empty-state-icon">📝</div>
          <div className="empty-state-text">You haven't created any questions yet</div>
          <button 
            className="add-question-button"
            onClick={() => navigate('/insert-question')}
          >
            Add New Question
          </button>
        </div>
      </div>
    );
  }

  const renderMobileView = (question) => (
    <div key={question._id} style={mobileCardStyle}>
      <div style={mobileQuestionStyle}>{question.question}</div>
      <div style={mobileDetailsStyle}>
        <div style={mobileDetailItemStyle}>
          <span style={mobileLabelStyle}>Marks:</span>
          <span>{question.marks}</span>
        </div>
        <div style={mobileDetailItemStyle}>
          <span style={mobileLabelStyle}>Low Mark Range:</span>
          <span>{question.lowMarkRange}</span>
        </div>
        <div style={mobileDetailItemStyle}>
          <span style={mobileLabelStyle}>High Mark Range:</span>
          <span>{question.highMarkRange}</span>
        </div>
        <div style={mobileDetailItemStyle}>
          <span style={mobileLabelStyle}>Created:</span>
          <span>{formatDateTime(question.createdAt)}</span>
        </div>
      </div>
      <div style={mobileActionsStyle}>
        <div style={{ position: 'relative' }}>
          <button
            style={addToButtonStyle}
            onClick={() => toggleDropdown(question._id)}
            disabled={addingToClass}
          >
            Add To
          </button>
          {showClassDropdown[question._id] && (
            <div style={dropdownStyle}>
              {Array.isArray(classes) && classes.length > 0 ? (
                classes.map(cls => (
                  <button
                    key={cls._id}
                    style={dropdownButtonStyle}
                    onClick={() => handleAddToClass(question._id, cls._id)}
                    disabled={addingToClass}
                  >
                    {cls.name}
                  </button>
                ))
              ) : (
                <div style={{ padding: '8px 12px', color: '#666' }}>
                  No active classes found
                </div>
              )}
            </div>
          )}
        </div>
        <button
          style={editButtonStyle}
          onClick={() => handleEdit(question._id)}
        >
          Edit
        </button>
        <button
          style={deleteButtonStyle}
          onClick={() => handleDelete(question._id)}
        >
          Delete
        </button>
      </div>
    </div>
  );

  return (
    <div className="container">
      <div className="header">
        <h1>Questions ({questions.length})</h1>
        {windowWidth <= 768 ? (
          <button 
            className="mobile-add-button"
            onClick={() => navigate('/insert-question')}
            aria-label="Add New Question"
          >
            +
          </button>
        ) : (
          <button 
            className="add-question-button"
            onClick={() => navigate('/insert-question')}
          >
            + Add New Question
          </button>
        )}
      </div>

      <div className="sort-section">
        <div className="sort-container">
          <label className="sort-label">Sort by:</label>
          <select 
            className="sort-select"
            value={sortBy}
            onChange={(e) => handleSort(e.target.value)}
          >
            {sortOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      {questions.length === 0 ? (
        <div className="empty-state">
          <span className="empty-icon">📝</span>
          <p>No questions found. Create your first question!</p>
        </div>
      ) : (
        <>
          {isMobile ? (
            <div className="mobile-container">
              {getPaginatedQuestions().map(renderMobileView)}
            </div>
          ) : (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th className="table-header" style={{width: isTablet ? "30%" : "auto"}}>Question</th>
                    <th className="table-header" style={{width: "80px"}}>Marks</th>
                    <th className="table-header" style={{width: "120px"}}>Low Mark Range</th>
                    <th className="table-header" style={{width: "120px"}}>High Mark Range</th>
                    <th className="table-header" style={{width: "160px"}}>Created</th>
                    <th className="table-header" style={{width: "220px"}}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {getPaginatedQuestions().map((q) => (
                    <tr key={q._id} className="table-row">
                      <td className="table-cell" style={{maxWidth: isTablet ? "200px" : "400px"}}>
                        <div className="question-cell">{q.question}</div>
                      </td>
                      <td className="table-cell">{q.marks}</td>
                      <td className="table-cell">{q.lowMarkRange}</td>
                      <td className="table-cell">{q.highMarkRange}</td>
                      <td className="table-cell">
                        {formatDateTime(q.createdAt)}
                      </td>
                      <td className="table-cell">
                        <div className="action-buttons-container">
                          <button
                            className="action-button add-to-button"
                            onClick={() => toggleDropdown(q._id)}
                            disabled={addingToClass}
                          >
                            Add To
                          </button>
                          {showClassDropdown[q._id] && (
                            <div className="dropdown">
                              {Array.isArray(classes) && classes.length > 0 ? (
                                classes.map(cls => (
                                  <button
                                    key={cls._id}
                                    className="dropdown-button"
                                    onClick={() => handleAddToClass(q._id, cls._id)}
                                    disabled={addingToClass}
                                  >
                                    {cls.name}
                                  </button>
                                ))
                              ) : (
                                <div className="dropdown-item">
                                  No active classes found
                                </div>
                              )}
                            </div>
                          )}
                          <button
                            className="action-button edit-button"
                            onClick={() => handleEdit(q._id)}
                          >
                            Edit
                          </button>
                          <button
                            className="action-button delete-button"
                            onClick={() => handleDelete(q._id)}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {totalPages > 1 && (
            <div className="pagination">
              <button 
                className={`pagination-button ${currentPage === 1 ? 'hidden' : ''}`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <div className="page-numbers">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                  <button
                    key={page}
                    className={`page-number ${currentPage === page ? 'active' : ''}`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                ))}
              </div>
              <button 
                className={`pagination-button ${currentPage === totalPages ? 'hidden' : ''}`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const containerStyle = {
  padding: "16px",
  maxWidth: "1200px",
  margin: "0 auto",
  marginTop: "80px",
};

const topBarStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "24px",
  paddingLeft: "24px",
  paddingRight: "24px",
};

const headerStyle = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "24px",
  paddingLeft: "24px",
  paddingRight: "24px",
};

const sortContainerStyle = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

const sortLabelStyle = {
  fontSize: "14px",
  color: "#495057",
  fontWeight: "500",
};

const sortSelectStyle = {
  padding: "8px 12px",
  borderRadius: "4px",
  border: "1px solid #ced4da",
  fontSize: "14px",
  color: "#495057",
  backgroundColor: "white",
  cursor: "pointer",
  ':hover': {
    borderColor: "#adb5bd",
  },
  ':focus': {
    outline: "none",
    borderColor: "#0066cc",
    boxShadow: "0 0 0 2px rgba(0,102,204,0.25)",
  },
};

const titleStyle = {
  fontSize: "20px",
  '@media screen and (minWidth: 768px)': {
    fontSize: "24px",
  },
  fontWeight: "600",
  color: "#1a1a1a",
  margin: 0,
};

const addButtonStyle = {
  backgroundColor: "#0066cc",
  color: "white",
  border: "none",
  padding: "8px 16px",
  '@media screen and (minWidth: 768px)': {
    padding: "10px 20px",
  },
  borderRadius: "6px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "500",
  transition: "background-color 0.2s",
  ':hover': {
    backgroundColor: "#0052a3",
  },
};

const tableContainerStyle = {
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  overflow: "visible"
};

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  fontSize: "14px",
};

const tableHeaderStyle = {
  backgroundColor: "#f8f9fa",
  color: "#495057",
  padding: "12px 16px",
  textAlign: "left",
  fontWeight: "600",
  borderBottom: "2px solid #e9ecef",
};

const tableRowStyle = {
  borderBottom: "1px solid #e9ecef",
  transition: "background-color 0.1s",
  ':hover': {
    backgroundColor: "#f8f9fa",
  },
};

const tableCellStyle = {
  padding: "12px 16px",
  color: "#212529",
  position: "relative",
};

const questionCellStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const loadingContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
  padding: "40px",
  color: "#6c757d",
};

const loadingSpinnerStyle = {
  width: "20px",
  height: "20px",
  border: "3px solid #f3f3f3",
  borderTop: "3px solid #3498db",
  borderRadius: "50%",
  animation: "spin 1s linear infinite",
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
};

const errorContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  padding: "16px",
  backgroundColor: "#fff3f3",
  color: "#dc3545",
  borderRadius: "6px",
  margin: "20px",
};

const errorIconStyle = {
  fontSize: "20px",
};

const emptyStateStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "40px",
  backgroundColor: "#f8f9fa",
  borderRadius: "8px",
  color: "#6c757d",
};

const emptyIconStyle = {
  fontSize: "32px",
  marginBottom: "16px",
};

const mobileContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};

const mobileCardStyle = {
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: '16px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
};

const mobileQuestionStyle = {
  fontSize: '16px',
  fontWeight: '500',
  marginBottom: '12px',
  lineHeight: '1.4',
};

const mobileDetailsStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '16px',
};

const mobileDetailItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
};

const mobileLabelStyle = {
  color: '#6c757d',
  fontWeight: '500',
};

const mobileActionsStyle = {
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',
  borderTop: '1px solid #e9ecef',
  paddingTop: '12px',
  marginTop: '8px',
};

const paginationStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  marginTop: "24px",
  padding: "16px",
};

const paginationButtonStyle = {
  padding: "8px 16px",
  border: "1px solid #dee2e6",
  borderRadius: "4px",
  backgroundColor: "white",
  color: "#495057",
  cursor: "pointer",
  fontSize: "14px",
  ':hover': {
    backgroundColor: "#e9ecef",
  },
  ':disabled': {
    cursor: "not-allowed",
    opacity: 0.5,
  },
};

const pageNumbersStyle = {
  display: "flex",
  gap: "8px",
};

const pageNumberButtonStyle = {
  padding: "8px 12px",
  border: "1px solid #dee2e6",
  borderRadius: "4px",
  backgroundColor: "white",
  color: "#495057",
  cursor: "pointer",
  fontSize: "14px",
  minWidth: "40px",
  ':hover': {
    backgroundColor: "#e9ecef",
  },
};

const pageNumberActiveStyle = {
  backgroundColor: "#0066cc",
  color: "white",
  borderColor: "#0066cc",
};

export default ViewQuestions; 
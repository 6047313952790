import React from 'react';
import './CustomPopup.scss';

const CustomPopup = ({ show, onHide, title, children }) => {
  if (!show) return null;

  return (
    <div className="custom-popup" onClick={onHide}>
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        <div className="popup-header">
          <h2>{title}</h2>
          <button className="close-button" onClick={onHide}>×</button>
        </div>
        <div className="popup-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomPopup; 